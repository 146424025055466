import { useEffect, useRef, useState } from "react";
import InputSearch from "../../components/layouts/inputWithIcon";
import { useSearchParams } from "react-router-dom";
// icon
import { IoSearchOutline } from "react-icons/io5"
import { IoStar } from "react-icons/io5";
import { MdFilterAltOff } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import FetchWithScroll from "./fetchWithScroll";

function FilterSidebar({brands,returnedOpen}) {

    const [searchParams,setSearchParams] = useSearchParams();
    const [ values, setValues]= useState({
        star: "3",
        is_new : "3",
        name : "",
        barnd_id : "",
        category_id : ""
    })
    useEffect(()=>{
        setValues({
            star: searchParams.get("star") ? searchParams.get("star") : "3",
            is_new : searchParams.get("is_new") ? searchParams.get("is_new") : "3",
            brand_id : searchParams.get("brand_id") ? searchParams.get("brand_id") : '',
            category_id : searchParams.get("category_id") ? searchParams.get("category_id") : '',
            name : searchParams.get("name") ? searchParams.get("name") : ''
        })
    },[searchParams])
    const handleChange = (event)=>{
        setValues(prev=>({...prev,name:event.target.value}))
       
        searchParams.set("page",1) 
        searchParams.set("name",event.target.value) 
        setSearchParams(searchParams) 
      
        
    }
    const handleChacked = (type,value,id) => {
        if(type == "star"){
            setValues(prev=>({...prev,star:id}))
            searchParams.set("star",id) 
        }else if( type == "is_new"){
            setValues(prev=>({...prev,is_new:id}))
            searchParams.set("is_new",id) 
        }
        searchParams.set("page",1)  
        setSearchParams(searchParams)
    }
    const resetFilter  =  ()=>{
        setSearchParams(new URLSearchParams()); // Clear all parameters
      }
    return ( <div>
        <div className="flex justify-end gap-2">
                
            <div className="icon-div cursor-pointer" onClick={resetFilter}>
                <MdFilterAltOff/>
            </div>
            <div onClick={()=>returnedOpen(false)} className="lg:hidden" > 
                <div className="icon-div"> <AiOutlineClose /> </div>
            </div>
        </div>
        <div className="py-2">
            <InputSearch value={values.name} onChange={handleChange} icon={<IoSearchOutline/>}  isDark={true} placeholder={"Search"} />
        </div>
        
        <div >
            <h4 className="pb-2">Brands</h4>
            <div className="flex gap-3 pl-5" >
                    <div><input checked={values.brand_id == ""} onChange={(e)=>{
                        searchParams.delete("brand_id") 
                        searchParams.set("page",1) 
                        setValues(prev=>({...prev, brand_id : ""}))
                        setSearchParams(searchParams)     
                    }} type="checkbox" className="custom-checkbox" /></div>
                    <div>ِAll</div>

                </div>
            <FetchWithScroll type="brand" />
            
            
        </div>

        <div >
            <h4 className="pb-2">Category</h4>
                <div className="flex gap-3 pl-5" >
                    <div><input checked={values.category_id == ""} onChange={(e)=>{
                        searchParams.delete("category_id") 
                        searchParams.set("page",1) 
                        setValues(prev=>({...prev, category_id : ''}))
                        setSearchParams(searchParams)     
                    }} type="checkbox" className="custom-checkbox" /></div>
                <div>ِAll</div>

            </div>
            <FetchWithScroll type="category" />
            
            
        </div>
       
        <div>
            <h4 className="pb-2">Star</h4>
            <div className="pl-5">
                 <div className="flex gap-3" >
                    <div><input checked={values.star == 3} onChange={(e)=>{
                        searchParams.delete("star") 
                        searchParams.set("page",1) 
                        setValues(prev=>({...prev, star : 3}))
                        setSearchParams(searchParams)     
                    }} type="checkbox" className="custom-checkbox" /></div>
                    <div>ِAll</div>

                </div>
                <div className="flex gap-3" >
                    <div><input  checked={values.star == 0} onChange={(e)=>handleChacked("star",e.target.checked,0)}  type="checkbox" className="custom-checkbox" /></div>
                    <div><IoStar className={`"text-zinc-700"`} /></div>

                </div>
                <div className="flex gap-3" >
                    <div><input  checked={values.star == 1} onChange={(e)=>handleChacked("star",e.target.checked,1)}  type="checkbox" className="custom-checkbox" /></div>
                    <div><IoStar className={`text-warning`} /></div>

                </div>
            </div>
            
        </div>
        {/* <div className="flex justify-center py-2">
            <div className="divider"></div>
        </div> */}
        <div>
            <h4 className="pb-2" >Is New</h4>
            <div className="pl-5">
                <div className="flex gap-3" >
                    <div><input checked={values.is_new == 3} onChange={(e)=>{
                        searchParams.delete("is_new")
                        searchParams.set("page",1)  
                        setValues(prev=>({...prev, is_new : 3}))
                        setSearchParams(searchParams)     
                    }} type="checkbox" className="custom-checkbox" /></div>
                    <div>ِAll</div>

                </div>
                <div className="flex gap-3" >
                    <div><input checked={values.is_new == 0} onChange={(e)=>handleChacked("is_new",e.target.checked,0)} type="checkbox" className="custom-checkbox" /></div>
                    <div>New</div>

                </div>
                <div className="flex gap-3" >
                    <div><input checked={values.is_new == 1} onChange={(e)=>handleChacked("is_new",e.target.checked,"1")} type="checkbox" className="custom-checkbox" /></div>
                    <div>reused</div>

                </div>
            </div>
            
        </div>

    </div> );
}

export default FilterSidebar;