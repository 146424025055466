import { useState ,useRef , useEffect } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useDispatch } from "react-redux";
import { userInfo } from "../../redux/actions/actionUser";
import { alert } from "../../redux/actions/alert";
import { notification } from "../../redux/actions/notification";
import { useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import RetypeEmail from "../../pages/auth/re-enterEmail";
import ChangePassword from "../../pages/auth/changePass";
import Profile from "../../pages/profile/profile";
import VerificationCode from "../../pages/auth/verification";
import { sendEmailForResetPassword } from "../../functionality/functions";
import { loadingAlert } from "../../redux/actions/loading";
import { IoPersonSharp } from "react-icons/io5";

function Avatar({data}) {
    const [ isOpen , setIsOpen ] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dropdownRef = useRef(null); // Create a ref for the dropdown
     // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const logout = async () => {
        setIsOpen(false)
        const {response,message} = await Helper({
            url : apiRoutes.auth.logout,
            method : "GET",
            hasToken : true
        })
        if(response){
            localStorage.removeItem("user")
            localStorage.removeItem("token")
            localStorage.removeItem("cart")
            window.location.reload()
        }else{
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
        }
    }
    const openResetPassword = async()=>{
        setIsOpen(false)
        dispatch(loadingAlert({
            isOpen : true
        }))       
        const { res,msg } = await sendEmailForResetPassword(JSON.parse(localStorage.getItem("user")).email)
        localStorage.removeItem("verification")
        localStorage.setItem("resetPassword",JSON.stringify({
            email : JSON.parse(localStorage.getItem("user")).email
        }))
        if(res){
            dispatch(loadingAlert({
                isOpen : false
            }))  
            dispatch(alert({
                isOpen : true,
                component : <VerificationCode/>
            }))
            
        }else{
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : msg
            }))
            dispatch(loadingAlert({
                isOpen : false
            })) 
        }  
    }
    return ( <div ref={dropdownRef} className="relative h-full  avatar flex items-center justify-center">
        <p onClick={()=>{setIsOpen(!isOpen)}} className="nav-icon cursor-pointer flex items-center justify-center">
            {/* {data.username.substring(0,2).toUpperCase()} */}
            <IoPersonSharp   />
        </p>
        {isOpen && <div className="absolute popup-avatar right-0 " >
            <p className="text-center"> {data.fullName}</p>
            <div className="hr" ></div>
            <div onClick={()=>{
                setIsOpen(false)
                dispatch(alert({
                    isOpen : true,
                    component : <Profile/>
                }))
                }} className="cursor-pointer py-2">My Profile</div>
            <div onClick={()=>{
                setIsOpen(false)
                dispatch(alert({
                    isOpen : true,
                    component : <ChangePassword/>
                }))
               
            }} className="cursor-pointer py-2">Change Password</div>
            <div onClick={openResetPassword} className="cursor-pointer py-2">Reset Password</div>
            <div onClick={()=>{
                setIsOpen(false)
                logout()}} className="cursor-pointer">Logout</div>

        </div>}
        
    </div> );
}

export default Avatar;