
import InputSearch from "../../components/layouts/inputWithIcon";
import CustomCaptcha from "../../components/layouts/captcha";
// for redux 
import { useSelector, useDispatch } from 'react-redux';
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useEffect, useState } from "react";
import { notification } from "../../redux/actions/notification";
import { alert } from "../../redux/actions/alert";

// Icons
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";

const validationSchema = Yup.object({
    currentPassword  : Yup.string().min(6,'Current Password must be at least 6 characters').required('Current Password is required'),
    password :Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
        .required('Password is required'),
    confirmPassword : Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
  });

function ChangePassword() {
    const dispatch = useDispatch();
    const { register, handleSubmit,trigger, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    const [loading, setLoading] = useState(false)

    const onPasswordChange = () => {
        // Trigger validation for confirmPassword when password changes
        trigger('confirmPassword');
  };
    const onSubmit = async (data) => {
        setLoading(true)
        const localStorageData = JSON.parse(localStorage.getItem("user"))
        const {response , message,  statusCode} = await Helper({
            url: apiRoutes.auth.changePassword(localStorageData.id),
            method:'PUT',
            body:{
                email : localStorageData.email,
                old_password: data.currentPassword,
                new_password : data.password
            },
            hasToken: true,
        })
        if(response){
            setLoading(false)
            dispatch(alert({
                isOpen : false,
                component : <></>,
            }))
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
        }else{
            dispatch(notification({
                isOpen : true,
                message :message,
                bg_color : "bg-error"
            }))
            setLoading(false)
        }

        
    };

    return ( <div className="flex justify-center">

               <form onSubmit={handleSubmit(onSubmit)} className="shadow card-auth rounded-lg p-5 md:p-8">
                    <div className="flex justify-end">
                
                        <div onClick={()=>dispatch(alert({
                                isOpen : false,
                                component : <></>
                            }))} className="flex justify-end cursor-pointer py-1">
                                <AiOutlineClose />
                        </div>
                    </div>
                    
                    <h2 >Change Password </h2>
                    <div className="py-5" >
                        <InputSearch isPassword={true} type="password" register={register("currentPassword")} icon={<IoLockClosedOutline/>} placeholder={"Current Password"} isDark={true}  />
                        {errors.currentPassword && <p className="p-0.5 text-error">{errors.currentPassword.message}</p>}
                    </div>
                    <div >
                        <InputSearch isPassword={true} onChange={onPasswordChange} type="password" register={register("password")} icon={<IoLockClosedOutline/>} placeholder={"New Password"} isDark={true}  />
                        {errors.password && <p className="p-0.5 text-error">{errors.password.message}</p>}
                    </div>
                    <div className="py-5" >
                        <InputSearch isPassword={true} type="password" register={register("confirmPassword")} icon={<IoLockClosedOutline/>} placeholder={"Retype Password"} isDark={true}  />
                        {errors.confirmPassword && <p className="p-0.5 text-error">{errors.confirmPassword.message}</p>}
                    </div>

                    <div className="flex justify-center">
                    
                        <button className="flex justify-center items-center"  disabled={loading}>
                            {loading ?<div className="loader"></div>:"Change Password"}
                        </button>
                    </div>
                </form>

    </div> );
}

export default ChangePassword;