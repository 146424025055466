import { useEffect, useState , useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "../../redux/actions/alert";
function Alert() {
    const dispatch = useDispatch()
    const dataRedux = useSelector(state => state.alert )
    const [ data , setData ] = useState({
        isOpen : false,
        component :<></>
    })
    const alertRef = useRef(null); // Create a ref for the dropdown
     // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
        if (alertRef.current && !alertRef.current.contains(event.target)) {
            dispatch(alert({
                isOpen : false,
                component :<></>
          }))
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(()=>{
        setData(dataRedux)
    },[dataRedux]) 
    return ( data.isOpen ? <div  className={`fixed alert `}>
        {data.component}
      </div> : "");
}

export default Alert;