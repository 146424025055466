
import InputSearch from "../../components/layouts/inputWithIcon";
import CustomCaptcha from "../../components/layouts/captcha";
// for redux 
import { useSelector, useDispatch } from 'react-redux';
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useEffect, useState } from "react";
import { sendEmailForResetPassword } from "../../functionality/functions";
import { notification } from "../../redux/actions/notification";
import { alert } from "../../redux/actions/alert";
import VerificationCode from "./verification";
import SignIn from "./signIn";
// Icons
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";

const validationSchema = Yup.object({
    email : Yup.string().email('Invalid email').required('Email is required'),
  });

function RetypeEmail() {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    const [loading, setLoading] = useState(false)

    const onSubmit = async (data) => {

        setLoading(true)
        localStorage.setItem("resetPassword",JSON.stringify({
            email : data.email,
            key : "forget"
        })) 
        const { res , msg }  = await sendEmailForResetPassword(data.email)
        if(res){
            dispatch(alert({
                isOpen : true,
                component : <VerificationCode/>
            }))
            setLoading(false)
        }else{
            dispatch(notification({
                isOpen : true,
                message :msg,
                bg_color : "bg-error"
            }))
            setLoading(false)
        }

        
    };

    return ( <div className="flex justify-center">

               <form onSubmit={handleSubmit(onSubmit)} className="shadow card-auth rounded-lg p-5 md:p-8">
                    <div className="flex justify-between">
                        <div onClick={()=>dispatch(alert({
                                isOpen : true,
                                component : <SignIn/>
                            }))} className="flex justify-end cursor-pointer py-1">
                                <IoArrowBack/>
                        </div>
                        <div onClick={()=>dispatch(alert({
                                isOpen : false,
                                component : <></>
                            }))} className="flex justify-end cursor-pointer py-1">
                                <AiOutlineClose />
                        </div>
                    </div>
                    
                    <h2 >Re-enter Your Email</h2>
                    <p className="py-5">Please re-enter your email to receive the code.</p>
                    <div className="pb-5">
                        <InputSearch register={register("email")} icon={<MdOutlineMail/>} placeholder={"Email"} isDark={true}  />
                        {errors.email && <p className="p-0.5 text-error">{errors.email.message}</p>}
                    </div>
                    <div className="flex justify-center">
                  
                        <button className="flex justify-center items-center" disabled={loading}>
                            {loading ?<div className="loader"></div>:"Next"}
                            </button>
                    </div>
                </form>

    </div> );
}

export default RetypeEmail;