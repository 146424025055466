import React , {useEffect} from "react";
import InputSearch from "../../components/layouts/inputWithIcon";
import { CgCode } from "react-icons/cg";
import { IoTimerOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
// for redux 
import { useDispatch } from 'react-redux';
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useState } from "react";
import { userInfo } from "../../redux/actions/actionUser";
import { sendEmailForResetPassword, sendEmailVerify, signInFunction } from "../../functionality/functions";
import { notification } from "../../redux/actions/notification";
import { alert } from "../../redux/actions/alert";
import ForgetPassword from "./forgetPassword";

const validationSchema = Yup.object({
    code: Yup.string().min(2, 'Code must be at least 2 characters').required('Code is required')
  });

function VerificationCode() {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(0); // Initialize timer at 0 seconds
    

    // Timer counting up from 0 to 30 seconds
    useEffect(() => {
        if(localStorage.getItem("verification") || localStorage.getItem("resetPassword") ){
            if (timer < 30) {
                const interval = setInterval(() => {
                    setTimer((prevTimer) => prevTimer + 1);
                }, 1000);
    
                // Clear the interval when the timer reaches 30 or the component unmounts
                return () => clearInterval(interval);
            }
        }else{
            window.history.back()
        }
        
    }, [timer]);
   
    const handleResend = async ()=>{
        if(timer == 30){
            setTimer(0)
            if(localStorage.getItem("verification")){
                const data = JSON.parse(localStorage.getItem("verification"))
                const {res,msg} = await sendEmailVerify(data.email,data.code)
                if(res){
                    dispatch(notification({
                        message : msg,
                        bg_color : "bg-success",
                        isOpen : true
                    }))
                }else{
                    dispatch(notification({
                        message : msg,
                        bg_color : "bg-error",
                        isOpen : true
                    }))
                }
            }else if(localStorage.getItem("resetPassword")){
                const data = JSON.parse(localStorage.getItem("resetPassword"))
                const {res , msg} = await sendEmailForResetPassword(data.email)
                if(res){
                    dispatch(notification({
                        message : msg,
                        bg_color : "bg-success",
                        isOpen : true
                    }))
                }else{
                    dispatch(notification({
                        message : msg,
                        bg_color : "bg-error",
                        isOpen : true
                    }))
                }
            }
            

        }
    }
    // Handle Confirm 
    const onSubmit = async (data) => {
        setLoading(true)
        if(localStorage.getItem("verification")){
            
            const { response,message} = await Helper({
                method : "POST",
                hasToken : true ,
                url : apiRoutes.auth.verify,
                body:{
                    verification_code : data.code
                }
            })
            if(response){
                
                dispatch(notification({
                    isOpen : true,
                    message : message,
                    bg_color : "bg-success"
                }))
                updateUser()
            }else{
                dispatch(notification({
                    isOpen : true,
                    message : message,
                    bg_color : "bg-error"
                }))
                setLoading(false)
            }

        }else if(localStorage.getItem("resetPassword")){
            const { response,message} = await Helper({
                method : "POST",
                hasToken : true ,
                url : apiRoutes.auth.verifyResetPassword,
                body:{
                    reset_token  : data.code,
                    email : JSON.parse(localStorage.getItem("resetPassword")).email
                }
            })
            if(response){
                setLoading(false)
                dispatch(notification({
                    isOpen : true,
                    message : message,
                    bg_color : "bg-success"
                }))
                dispatch(alert({
                    isOpen : true,
                    component : <ForgetPassword/>
                }))
            }else{
                setLoading(false)
                dispatch(notification({
                    isOpen : true,
                    message : message,
                    bg_color : "bg-error"
                }))
            }
        }
        
        
    };
    const updateUser = async()=>{
        const data = JSON.parse(localStorage.getItem("verification"))
        const {response, message} = await Helper({
            url: apiRoutes.auth.update(data.id),
            hasToken : true,
            method : "PUT",
            body : {
                is_active : "1",
                is_verified : "1"
            }
        })
        if(response){
            const { res , msg } = await signInFunction(data.email,data.password)
            if(res){
                setLoading(false)
                window.location.reload()
                dispatch(userInfo({
                    email: res.data.email,
                    username: res.data.username,
                    photo: "",
                    mobile : res.data.Mobile,
                    fullName : res.data.full_name,
                    id: res.data.id
                }))
                localStorage.setItem("user",JSON.stringify({
                    email: res.data.email,
                    username: res.data.username,
                    photo: "",
                    mobile : res.data.Mobile,
                    fullName : res.data.full_name,
                    id: res.data.id,
                }))
                localStorage.setItem("token",res.data.token)
                localStorage.removeItem("verification")
                
            }else{
                setLoading(false)
                dispatch(notification({
                    isOpen : true,
                    message : msg,
                    bg_color : "bg-error"
                }))
            }
        }else{
            setLoading(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
        }
                

    }
 
    return ( <div className="flex justify-center mb-8">
                <form onSubmit={handleSubmit(onSubmit)} className="shadow card-auth rounded-lg  p-5 md:p-8">
                    <div onClick={()=>dispatch(alert({
                            isOpen : false,
                            component : <></>
                        }))} className="flex justify-end cursor-pointer py-1">
                            <AiOutlineClose />
                    </div>
                    <h2 className="pb-5">Verification </h2>
                    <p className="pb-2">Please check your email<b> {localStorage.getItem("verification")?JSON.parse(localStorage.getItem("verification")).email:(localStorage.getItem("resetPassword")?JSON.parse(localStorage.getItem("resetPassword")).email:"")} </b>for a verification code and confirm if you have received it.</p>
                    <div>
                        <InputSearch register={register("code")} icon={<CgCode/>} placeholder={"Enter Code"} isDark={true}  />
                        {errors.code && <p className="p-0.5 text-error">{errors.code.message}</p>}
                    </div>
                    <div className="text-warning flex justify-center text-bold text-center py-4">
                        {/* <p> <h3>{timer}</h3> seconds out of 30 seconds have passed</p> */}
                        <div className="flex timer min-h-10">
                            <div className="flex items-center px-2  " >
                                <div><IoTimerOutline style={{fontSize:"30px"}}/></div> 
                            </div>
                            <div className="flex items-center">
                                <div >{timer}s</div>
                            </div> 
                        </div>
                        
                    </div>
                    <h5 className=""> Didn't get the email ? 
                         <span disabled={timer < 30 ? true: false} onClick={()=>handleResend()} className={`link-auth ${timer < 30 ? "!text-neutral-600 !cursor-auto": ""}`}>Resend after {30 - timer} seconds</span> </h5> 
                         <p className="text-sm text-neutral-500 mt-2">
                                If you don't see the email in your inbox, please check your Spam or Junk folder.
                            </p>
                    {/* <h5 className=""> If you haven't received an email, <span disabled={timer < 30 ? true: false} onClick={()=>handleResend()} className={`link-auth ${timer < 30 ? "!text-neutral-600 !cursor-auto": ""}`}>Resend</span> </h5> */}
                    <div className="flex justify-center py-3">
                        <button className="flex justify-center items-center"  disabled={loading}>
                            {loading ?<div className="loader"></div>:"Confirm"}
                        </button>
                    </div>
                </form>
	
    </div> );
}

export default VerificationCode;