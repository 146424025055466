import { useEffect, useState } from "react";

import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import InputSearch from "./inputWithIcon";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
function MobileInput({register,value,returnedCountry,country_id,isOpenMenu=true}) {
    const [ countries, setCountries] = useState([])
    const [ selectedCountry, setSelectedCountry,] = useState({})
    const [number ,setNumber ] = useState()
    const [isOpen ,setIsOpen ] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getCountry(signal)
        return () => controller.abort()
    },[])
    useEffect(()=>{
        setNumber(value)
    },[value])
    const getCountry = async (signal)=>{
        setLoading(true)
        const { response , message } = await Helper({
            url : apiRoutes.country.all,
            method : "GET",
            signal
        })
        if(response){
            
            if(country_id){
                response.data.forEach((ele)=>{
                    if(ele.id == country_id){
                        setSelectedCountry(ele)
                    }
                })
            }else{
                setCountries(response.data)
                setSelectedCountry(response.data[0])
                returnedCountry(response.data[0])
            }
           
            setLoading(false)
        }
    }
    const handleSearch = (e) => {
        if(e.target.value.length >2 ){
            const searchQuery = e.target.value.toLowerCase();
            const filtered = countries.filter((country) =>
                country.country_name.toLowerCase().includes(searchQuery)
            );
            setCountries(filtered);
        }else{
            getCountry()
        }
       
    };
    return ( <div className="mobile-input">
        <div className={`flex min-w-12 ${isOpenMenu && "cursor-pointer"}`} onClick={()=>{
            if(isOpenMenu)
                setIsOpen(!isOpen)
            
        }}>
            {loading ? <div className="loader-dark0"></div> :
            <img className="w-8 rounded" alt={ "country_name" in selectedCountry && selectedCountry.country_name} src={"iso_code" in selectedCountry ?`https://flagcdn.com/w320/${selectedCountry.iso_code.toLowerCase()}.png` :""} />}
            {isOpenMenu && <div className="flex cursor-pointer items-center">
                {isOpen ? <IoMdArrowDropup/> : <IoMdArrowDropdown/>}
                
            </div>}
            
        </div>
        <div className="w-fit whitespace-nowrap">
            ( { "country_code" in selectedCountry && selectedCountry.country_code} )
        </div>
        <div className="w-full">
            <input  disabled={!isOpenMenu} placeholder="XX XX XX" {...register} value={number} onChange={(e)=>{
                register.onChange(e)
                setNumber(e.target.value)
                }} type="number" />
        </div>
        <ul className={`${isOpen?"block":"hidden"} menu-countries`}>
            <li className="py-2">
                <InputSearch onChange={handleSearch} placeholder={"Search"} isDark={true}/>
            </li>
            {countries.length>0 ? countries.map((ele)=>{
                const code = ele.iso_code.toLowerCase()
                return <li onClick={()=>{setSelectedCountry(ele)
                    returnedCountry(ele)
                    setIsOpen(false)
                }} className="flex mb-2 gap-2 cursor-pointer hover:text-stone-700" key={`Countries_${ele.country_name}`}>
                <img className="w-8" alt={ele.name} src={`https://flagcdn.com/w320/${code}.png`} />
                <div>
                    {ele.country_name}
                </div>
                <div>{`(${ele.country_code})`}</div>
            </li>
            }):<div className="flex justify-center py-4">
                <p>No Country founded</p>
                </div>}
        </ul>
    </div> );
}

export default MobileInput;