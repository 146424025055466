import React from 'react';
import { IoMdArrowDropleft } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
const Paginator = ({ countPages, currentPage = 1, onPageChange }) => {
  // Generate pagination items
  const getPaginationItems = () => {
    const items = [];
    for (let i = 1; i <= countPages; i++) {
      items.push(i);
    }
    return items;
  };

  return (
    <nav className="pagination">
      <ul>
        {/* Previous Button */}
        <li className='flex items-center'>
          <div
            className={`pagination-button ${currentPage == 1 ?"disable":""}`}
            onClick={() => {
              if(currentPage != 1 )
                onPageChange(currentPage - 1)
            }}
          >
            <IoMdArrowDropleft/>
          </div>
        </li>

        {/* Page Numbers */}
        {getPaginationItems().map((page) => (
          <li key={page}>
            <div style={{fontSize:"13px"}}
              className={`pagination-button cursor-pointer font-bold  ${
                page == currentPage ? 'active' : ''
              }`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </div>
          </li>
        ))}

        {/* Next Button */}
        <li  className='flex items-center'>
          <div
            className={`pagination-button ${currentPage == countPages ?"disable":""}`}
            onClick={() =>{
              if(currentPage != countPages)
                onPageChange(currentPage + 1)
            }}
          >
            <IoMdArrowDropright/>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Paginator;
