import { useEffect, useState } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes, imageHost } from "../../functionality/apiRoutes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { currency } from "../../functionality/data";

import sad from "../../images/sad.png"
// icons
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { notification } from "../../redux/actions/notification";
import { MdOutlineDelete } from "react-icons/md";
import { alert } from "../../redux/actions/alert";
import {cart as cartFun   } from "../../redux/actions/cart";
import ConfirmDeleted from "../../components/layouts/confirm";
import CartCard from "../../components/layouts/cartCard";

function EditOrder() {
    const {id} =useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ loading,setLoading] = useState(true)
    const [ loadingSubmit,setLoadingSubmit] = useState(false)
    const [ totalCart,setTotalCart] = useState(0)
    const [ loadingUpdate,setLoadingUpdate] = useState(false)
    const [ data,setData] = useState([])  
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if(!localStorage.getItem("user"))
            navigate("/")
        getOrder(signal)

        return ()=> controller.abort()
    },[])
    useEffect(()=>{
        const cartArrary = [...data]
        let results = []
        let totalResult = 0
        cartArrary.forEach(ele =>{
            results.push(parseFloat(ele.pivot.amount)*parseFloat(ele.pivot.price))
        })
        for(let i=0; i<results.length ; i++){
            totalResult += results[i]
        }
        setTotalCart(totalResult)
    },[data])
    const getOrder = async (signal) => {
        const {response, message} = await Helper({
            url : apiRoutes.order.getOne(id),
            method : "GET",
            hasToken : true,
            signal
        })
        if(response){
            setLoading(false)
            setData(response.data)
        }

    }
    const updateItem = async (id_item,amount,proccess,index,item)=>{
        setLoadingUpdate(true)
        const cartInfo = data
        const cartItems = [...data]
        if(proccess == "plus"){
            cartItems[index]["pivot"]["amount"] = item.pivot.amount+1
        }else{
            cartItems[index]["pivot"]["amount"] = item.pivot.amount-1
        }
        setData(cartItems )
        const {response, message} = await Helper({
            url : apiRoutes.order.update ,
            method : "PUT",
            hasToken : true,
            params : {
                order_id : id,
                item_id : id_item,
                quantity : amount

            },
        })
        if(response){
            setLoadingUpdate(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-success"
            }))
            getOrder()
        }else{
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
            const cartInfo = data
        const cartItems = [...data.items]
       
        
        if(proccess == "plus"){
            cartItems[index]["pivot"]["amount"] = item.pivot.amount-1
        }else{
            cartItems[index]["pivot"]["amount"] = item.pivot.amount+1
        }
        setData(cartItems)
        }

    }
    const deleteItem =  (id,name)=>{
        dispatch(alert({
            isOpen : true ,
            component : <ConfirmDeleted  onClick={()=>removeItem(id)} message={`Are you sure delete ( ${name} ).`} loading={false}  label={"Confirm"}  />
        }))
    }
    const removeItem = async(id,name)=>{
        dispatch(alert({
            isOpen : true,
            component : <ConfirmDeleted  loading={true} message={`Are you sure delete ( ${name} ).`} label={"Confirm"} />
          }))
        const {response, message} = await Helper({
            url : apiRoutes.cart.deleteItem,
            method : "DELETE",
            hasToken : true,
            params : {
                item_id : id ,
            },
        })
        if(response){
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-success"
            }))
            getOrder()

            dispatch(alert({
                isOpen : false,
                component : <></>
              }))
        }else{
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
            dispatch(alert({
                isOpen : false,
                component : <></>
              }))

       
        
      
        }
    }
 
    return ( loading?<div className='min-h-96  w-full flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

        </div>: <div>
        <h2 className="flex justify-center pb-3">Order ID : #{id} </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 ">
            {data.length > 0 ? data.map((item,index)=>(<CartCard updateItem={(id,value,type,i,item )=>{
                updateItem(id,value,type,i,item )
            }} deleteItem={(id,name)=>{
                    deleteItem(id,name)
            }}  minimum_sale={item?.Minimum_sale} index={index} item={item} key={`Item_Update_Cart_${item.name?item.name:"-"}`} />)): <div className='min-h-96 w-full '>
 
            <div className="flex justify-center w-full">
                <img alt="no Items" src={sad} />
            </div>
            <h5 className="flex justify-center py-2" >No Items </h5>

            </div> }

        </div>
        </div>
        
        
         );
}

export default EditOrder;