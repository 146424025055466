import { number } from "yup";

const initialValues = {
    number : ""
}

export const reducerCart = (state = initialValues , action)=>{
switch(action.type){
    case "CART-ITEMS-NUMBER":
        return{
            ...state,
            number : action.number
        }

    break;
    default :
        return { ...state }
        break;
}
}