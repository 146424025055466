import { useEffect, useState , useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { notification } from "../../redux/actions/notification";
function Notification() {
    const dispatch = useDispatch()
    const dataRedux = useSelector(state => state.notification )
    const [ data , setData ] = useState({
        isOpen : false,
        message : "",
        bg_color : ""
    })
    const noteRef = useRef(null); // Create a ref for the dropdown
     // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
        if (noteRef.current && !noteRef.current.contains(event.target)) {
            dispatch(notification({
                isOpen : false,
                message : "",
                bg_color : ""
          }))
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(()=>{
        setData(dataRedux)
    },[dataRedux]) 
    return ( data.isOpen ? <div ref={noteRef} className={`fixed top-4 right-4 ${data.bg_color} text-white px-4 py-2 rounded-lg shadow-lg animate-slide-in`}>
        <div onClick={()=>dispatch(notification({
              isOpen : false,
              message : "",
              bg_color : ""
        }))} className="flex justify-end cursor-pointer py-1">
            <AiOutlineClose />
        </div>
        <h5>{data.message}</h5>
      </div> : "");
}

export default Notification;