import React, { useRef, useState } from "react";
import Select from "react-select";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { FaSortAmountUp } from "react-icons/fa";
import InputSearch from "../../components/layouts/inputWithIcon";
import { MdOutlinePriceChange } from "react-icons/md";
import imageCompression from 'browser-image-compression';
import { FaRegImage } from "react-icons/fa6";
import { FaSortAmountDownAlt } from "react-icons/fa";
function ItemForm({ index,onRemove,loading,coins, onSave,group_id,isSaved,brands,categories}) {
    const inputRef = useRef()
    const status = [{
        label : "New",
        value : 0
    },{
        label : "Reused",
        value : 1
    }]
    // Submit handler
    const [item, setItem] = useState({
            name : "",
            brand_id : "",
            category_id : "",
            description : "",
            price : "",
            amount : "",
            total : "",
            status : null,
            img : "",
            group_id: group_id,
            isSaved : false,
            id : "",
            preview : "",
            loading : false,
            coin_id :"",
            minimum_sale :""

    })
    const [message,setMessage] = useState("") 
    const onSaveObject = ()=>{
        if(item.name.length > 3 ){
            if(item.brand_id && item.category_id && item.status!=null && item.coin_id ){
                if(item.price > 0 && item.amount > 0 && item.minimum_sale > 0){
                    if(item.img != ""){
                        console.log(parseFloat(item.minimum_sale),'--',parseFloat(item.amount));
                        
                        if(parseFloat(item.amount) > parseFloat(item.minimum_sale)){
                            onSave(item)
                        }else{
                            // setItem(prev=>({...prev,minimum_sale:parseFloat(item.amount)-1}))
                            setMessage(`The minimum sale value cannot be more than the ( ${parseFloat(item.amount)} ). Please adjust the values.`)    
                        }
                        
                    }else{
                        setMessage("Image is required")   
                    }
                    
                }else{
                    setMessage("There are invalid number ")
                }
            }else{
                setMessage("All selected are required ")
            }
        }else{
                setMessage("Name Feild must be more 4 characters ")
        }
       
    }

    const handleChangeItem = (type,value)=>{
        setMessage("")
        setItem(prev=>({...prev,[type]:value}))
       
        if(type == "price" || type == "amount"){
            // console.log(parseInt(prev.amount)*parseInt(prev.price));
            
            setItem(prev=>({...prev,total:parseInt(prev.amount)*parseInt(prev.price)}))
        }
            
        
    }
    const imageUpload = async(event)=>{
        setMessage("")
        const imageFile = event.target.files[0];
        const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
        }
        try {
        const compressedFile = await imageCompression(imageFile, options);

        setItem(prev=>({...prev, img : compressedFile,
            preview :URL.createObjectURL(event.target.files[0])  , 
        }))
        } catch (error) {
        console.log(error);
        }
    }
    
    return ( <div className="flex items flex-col gap-3">
        
            <div>
            <InputSearch onChange={(e)=>handleChangeItem("name",e.target.value)} value={item.name} icon={<MdOutlineDriveFileRenameOutline/>} isDark={true}  placeholder={"Name Item"} />
            </div>
        
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div >
                    <InputSearch icon={<FaSortAmountUp/>} onChange={(e)=>handleChangeItem("amount",e.target.value)} value={item.amount} isDark={true} type="number" placeholder={"Amount"} />
                </div>
                <div >
                    <InputSearch icon={<FaSortAmountDownAlt/>} onChange={(e)=>handleChangeItem("minimum_sale",e.target.value)} value={item.minimum_sale} isDark={true} type="number" placeholder={"Minimum Amount"} />
                </div>
                <div >
                    <InputSearch icon={<MdOutlinePriceChange/>} onChange={(e)=>handleChangeItem("price",e.target.value)} value={item.price} isDark={true} type="number" placeholder={"Price"} />
                </div>
                <div>
                    <Select
                            options={coins}
                            value={coins.find(option => option.value == item.coin_id)}
                            onChange={(option) => handleChangeItem("coin_id",option.value)}
                            placeholder="Currency"
                        />
                </div>
                <div >
                     <InputSearch icon={<MdOutlinePriceChange/>}  value={item.total} isDark={true} disabled={true} type="number" placeholder={"Total"} />
                    
                </div>
                <div>
                    <Select
                            options={brands}
                            value={brands.find(option => option.value == item.brand_id)}
                            onChange={(option) => handleChangeItem("brand_id",option.value)}
                            placeholder="Brand"
                        />
                </div>
                <div>
                    <Select
                            options={categories}
                            value={categories.find(option => option.value == item.category_id)}
                            onChange={(option) =>handleChangeItem("category_id",option.value)}
                            placeholder="Category"
                        />
                </div>
                <div>
                    <Select
                            options={status}
                            value={status.find(option =>option.value == item.status)}
                            onChange={(option) => handleChangeItem("status",option.value)}
                            placeholder="Status"
                        />
                </div>
            </div>
            <div >
                <textarea onChange={(e)=>handleChangeItem("description",e.target.value)} value={item.description}   className="bg-inherit w-full"   placeholder={"Description"} />
            </div>
            {item.preview != "" && <div>
                <img style={{borderRadius:"50%"}} className="w-20 h-20" alt="item" src={item.preview} />
            </div>}
            
            <div>
                <div  className="cursor-pointer flex gap-2 " onClick={()=>{
                    inputRef.current.click()
                }} >
                    <div className="gap-2 p-2 rounded bg-zinc-300 items-center flex">
                        <FaRegImage />Choose Image</div>
                    </div>
                <input type="file"  ref={inputRef} className="hidden" placeholder="Choose Image" accept="image/jpeg, image/png, image/gif, image/webp, image/svg+xml" onChange={imageUpload}/>
            </div>
            <p className="text-error text-center">
                {message.length > 0 && message}
            </p>
            <div className="flex justify-center">
                {!isSaved && <button  onClick={onSaveObject} className="flex justify-center items-center"  disabled={loading || item.preview == ""} >
                        {loading ? <div className="loader"></div>:"Save"}
                    </button>
                    }
                {isSaved && <div>
                    <button className="mx-2 button-error" onClick={onRemove} >remove</button>
                    <button className="mx-2 " onClick={onSaveObject} >Save Changes</button>
                    </div>}
            </div>
            

       
    </div> );
}

export default ItemForm;