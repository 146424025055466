// icon
import { useEffect, useState } from "react";
import { LuShoppingCart } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCartItems } from "../../functionality/functions";
import { cart } from "../../redux/actions/cart";

function CartIcon() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartRedux = useSelector(state => state.cart.number)
    const [number ,setNumber] = useState(0)
    useEffect(()=>{
        if(cartRedux != ""){
            setNumber(cartRedux)
        }else if (localStorage.getItem("cart") && localStorage.getItem("cart") != "" ){
            setNumber(localStorage.getItem("cart"))
        }else{
            setNumber(0)
        }
    },[cartRedux])
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        const getCartFun = async (signal)=>{
            const {res , msg} = await  getCartItems(signal)
            
            if(res){
                localStorage.setItem("cart",res.data.items.length)
                dispatch(cart({
                    number : res.data.items.length
                }))
            }
        }
        getCartFun(signal)

        return () => controller.abort()
    },[])
    return ( <div className="flex cursor-pointer relative">
        <div onClick={()=>navigate("/cart")} className="flex nav-icon items-center justify-center cursor-pointer relative z-50">
            <LuShoppingCart/>
        </div>
        <div style={{borderRadius:"50%",top:"-8px",right :"-3px",fontSize:"11px"}} className="bg-error z-50 font-bold text-white absolute w-5 h-5 flex items-center justify-center" >
            <div>{number}</div>
        </div>
      </div> );
}

export default CartIcon;