import { useState } from "react";
import Categories from "../../components/sections/Categories";
import ContactUs from "../../components/sections/contact";
import Intro from "../../components/sections/introduce";
import Products from "../../components/sections/products";
import Services from "../../components/sections/services";
import Brands from "../../components/sections/brands";
import LatestItems from "../../components/sections/latestItems";
import StarItems from "../../components/sections/starItems";
import VistorsItems from "../../components/sections/visitorsItems";

function LandingPage() {
    return ( <>
        <Intro/>
        <Products/>
        <LatestItems/>
        <StarItems/>
        <VistorsItems/>
        <Categories/>
        <Brands/>
        <Services/>
        <ContactUs/>
    </> );
}

export default LandingPage;