import InputSearch from "./inputWithIcon";
import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate ,useSearchParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "./avatar";
import { alert } from "../../redux/actions/alert";
import SignIn from "../../pages/auth/signIn";
import SignUp from "../../pages/auth/signup";

// icon
import { IoSearchOutline } from "react-icons/io5";
import { IoMenuSharp } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { LuShoppingCart } from "react-icons/lu";

// logo
import logo from "../../images/back.png"
import SearchAlert from "./inputAlert";
import CartIcon from "./cartIcon";

const Item = ({isCollapseRoot,returnedIsOpen,e})=>{
    const [ isCollapse, setIsCollapse ] = useState(false)
    useEffect(()=>{setIsCollapse(isCollapseRoot)},[isCollapseRoot]) 
    
    return  <div className="menu-item root-menu-collapse" onClick={()=>setIsCollapse(!isCollapse)} key={e.name+"link_list_navbar"}>
    <div className="flex items-center justify-center"> {e.name} {isCollapse? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>} </div>
    <div className= {`${isCollapse?"menu-collapse-open":"menu-collapse hidden"}`}>
        {e.children.map((child,index)=>(<div className={`menu-collapse-item p-2 flex flex-row gap-1`} key={`Item_${child.name}`}>
            <Link onClick={()=>{
                setIsCollapse(false);
                returnedIsOpen(false)
            }}  to={child.link}> 
             {child.name}
            </Link>
        </div>))}
    </div>

    </div>
}

function NavBar() {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const userRedux = useSelector(state => (state.user))
    const dispatch = useDispatch()
    const [user, setUser] = useState({})
    const [ search , setSearch ] = useState("")
    const [searchParams,setSearchParams] = useSearchParams(); 
    const dataWithoutLoginInfo = [{
        name : "Home",
        link :'/',
    },{
        name : "Items",
        link : '/items',
    },{
        name : "About Us",
        link : '/page/8',
    },{
        name : "Contact Us",
        link : '/page/12',
    }]
    const dataWithLogedIn = [{
        name : "Home",
        link :'/',
    },{
        name : "Requests",
        link :'/requests',
    },{
        name : "Add Requests",
        link :'/requests/add',
    },{
        name : "Orders",
        link :'/orders',
    },{
        name : "Items",
        link : '/items',
    },{
        name : "About Us",
        link : '/page/8',
    },{
        name : "Contact Us",
        link : '/page/12',
    }]
    const [list , setList] = useState(dataWithoutLoginInfo)
    const searchRef = useRef("")

    useEffect(()=>{
        const storageUserInformation = JSON.parse(localStorage.getItem("user"))
        if( "email" in userRedux && userRedux.email != "" ){
            setUser({
                email: userRedux.email,
                username: userRedux.username,
                photo: "",
                password : userRedux.password,
                mobile : userRedux.mobile,
                fullName : userRedux.fullName,
                id: userRedux.id
            })
            setList(dataWithLogedIn)
        }else if ( localStorage.hasOwnProperty("user") && storageUserInformation.email != ""){
            setUser(storageUserInformation)
            setList(dataWithLogedIn)
        }else {
            setUser({})
            setList(dataWithoutLoginInfo)
        }
    },[userRedux])
    useEffect(()=>{
        setSearch(searchParams.get("search") ? searchParams.get("search") : "" )
    },[searchParams])
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
      if (window.scrollY > 50) { // Change 50 to the scroll position threshold
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  
    useEffect(() => {
      // Add the scroll event listener
      window.addEventListener('scroll', handleScroll);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const handleKeyDown = (event) => {
        if (event.key == "Enter") {
            navigate(`/items?search=${search}`)
        }
    };
    const location = useLocation();  // Get current location

// Custom function to check if either of the routes should be active
    const isActive = (link) => {  
        return location.pathname === link ;
    };
    return ( <div className={`flex justify-between items-center px-5 md:px-5 xl:px-24 py-4 md:py-5 ${scrolled?"fixed":""} nav`}>
        <div className="flex gap-8 ">
            <div className="flex justify-end items-center">
                <div className="flex">
                    <div className="flex md:hidden items-center p-1 "
                    onClick={()=>setIsOpen(true)} style={{fontSize:"30px"}}><IoMenuSharp style={{fontSize:"20px"}}/></div>
                    <Link className="flex" to={"/"}><div className="flex justify-center items-center">
                        <img className=" w-20 md:w-20" alt="logo" src={logo} />
                        </div>
                    </Link>
                </div>
            </div>
            <div className={`menu-nav ${isOpen?"":"hidden"} md:flex items-center`}>
                <div onClick={()=>setIsOpen(false)} className="flex justify-end md:hidden p-2 cursor-pointer close-icon">
                    <AiOutlineClose />
                </div>
                <h2 className="flex justify-center p-2 md:hidden">
                    <img className="w-20" alt="logo" src={logo} />
                </h2>
                <div className="flex gap-5 items-center menu">
                    {list.map((e,i)=>(<div className="menu-item" onClick={()=>setIsOpen(false)} key={e.name+"link_list_navbar"}>
                            <NavLink className={isActive(e.link) ? "active-link" : ""} onClick={()=>navigate(e.link)} to={e.link}>{e.name}</NavLink>
                            
                        </div>))}
                    
                </div>
            </div>
            
        </div>
        
        <div className="flex items-center gap-1 md:gap-2">
            <div className="lg:hidden nav-icon cursor-pointer justify-center flex items-center " onClick={()=>{
                dispatch(alert({
                    isOpen : true,
                    component : <SearchAlert onClick={()=>{
                        navigate(`/items?search=${searchRef.current}`)
                        dispatch(alert({
                            isOpen : false,
                            component : <></>
                        }))}} message={"Write value search"} returnedValue={(res)=>searchRef.current = res } label={"search"} /> 
                }))
            }} style={{fontSize:"20px"}}>
                <IoSearchOutline/>
            </div>
            <div className="lg:flex hidden">
                <InputSearch value={search} onKey={handleKeyDown} onChange={(res)=>setSearch(res.target.value)}   icon={<IoSearchOutline/>} isDark={true} placeholder={"Search"} />
            </div>
            {
              "email" in user && <CartIcon/>
            }
            <div>
                {"email" in user ?<Avatar data={user} />:<div className="flex gap-2">
                <button className="nav-btn" onClick={()=>{
                    dispatch(alert({
                        isOpen : true,
                        component : <SignIn/>
                    }))
                }}> Sign In</button>
                <button className="nav-btn" onClick={()=>{
                    dispatch(alert({
                        isOpen : true,
                        component : <SignUp/>
                    }))
                }}> Sign up</button>
                    </div>}
                
            </div>
        
            
        </div>
        
    </div> );
}

export default NavBar;