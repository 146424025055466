import { useEffect, useState } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useNavigate } from "react-router-dom";

import sad from "../../images/sad.png"
// icons
import { useDispatch } from "react-redux";
import { notification } from "../../redux/actions/notification";
import { alert } from "../../redux/actions/alert";
import {cart as cartFun   } from "../../redux/actions/cart";
import ConfirmDeleted from "../../components/layouts/confirm";
import CartCard from "../../components/layouts/cartCard";

function Cart() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ loading , setLoading] = useState(true)
    const [ loadingSubmit , setLoadingSubmit] = useState(false)
    const [ totalCart , setTotalCart] = useState(0)
    const [ loadingUpdate , setLoadingUpdate] = useState(false)
    const [ cart , setCart] = useState({
        items:[]
    })  
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if(!localStorage.getItem("user"))
            navigate("/")
        getCart(signal)

        return ()=> controller.abort()
    },[])
    useEffect(()=>{
        const cartArrary = [...cart.items]
        let results = []
        let totalResult = 0
        cartArrary.forEach(ele =>{
            results.push(parseFloat(ele.pivot.amount)*(parseFloat(ele.pivot.price)+parseFloat(ele.commission)))
        })
        for(let i=0; i<results.length ; i++){
            totalResult += results[i]
        }
        setTotalCart(totalResult)
    },[cart])
    const getCart = async (signal) => {
        const {response, message} = await Helper({
            url : apiRoutes.cart.getCart,
            method : "GET",
            hasToken : true,
            signal
        })
        if(response){
            setLoading(false)
            setCart(response.data)
            localStorage.setItem("cart",response.data.items.length)
            dispatch(cartFun({
                number : response.data.items.length
            }))
        }

    }
    const updateItem = async (id,amount,proccess,index,item)=>{
        setLoadingUpdate(true)
        const cartInfo = cart.cart
        const cartItems = [...cart.items]
        if(proccess == "plus"){
            cartItems[index]["pivot"]["amount"] = item.pivot.amount+1
        }else{
            cartItems[index]["pivot"]["amount"] = item.pivot.amount-1
        }
        setCart({
            cart : cartInfo,
            items : cartItems 
        })
        const {response, message} = await Helper({
            url : apiRoutes.cart.updateItem,
            method : "PUT",
            hasToken : true,
            params : {
                item_id : id ,
                amount :  amount
            },
        })
        if(response){
            setLoadingUpdate(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-success"
            }))
            getCart()
        }else{
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
            const cartInfo = cart.cart
        const cartItems = [...cart.items]
       
        
        if(proccess == "plus"){
            cartItems[index]["pivot"]["amount"] = item.pivot.amount-1
        }else{
            cartItems[index]["pivot"]["amount"] = item.pivot.amount+1
        }
        setCart({
            cart : cartInfo,
            items : cartItems 
        })
        }

    }
    const deleteItem =  (id,name)=>{
        dispatch(alert({
            isOpen : true ,
            component : <ConfirmDeleted  onClick={()=>removeItem(id)} message={`Are you sure delete ( ${name} ).`} loading={false}  label={"Confirm"}  />
        }))
    }
    const removeItem = async(id,name)=>{
        dispatch(alert({
            isOpen : true,
            component : <ConfirmDeleted  loading={true} message={`Are you sure delete ( ${name} ).`} label={"Confirm"} />
          }))
        const {response, message} = await Helper({
            url : apiRoutes.cart.deleteItem,
            method : "DELETE",
            hasToken : true,
            params : {
                item_id : id ,
            },
        })
        if(response){
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-success"
            }))
            getCart()

            dispatch(alert({
                isOpen : false,
                component : <></>
              }))
        }else{
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
            dispatch(alert({
                isOpen : false,
                component : <></>
              }))

       
        
      
        }
    }
    const checkout = async ()=>{
        setLoadingSubmit(true)
        const {response , message } = await Helper({
            url : apiRoutes.cart.chekout,
            hasToken : true,
            method : "GET"
        })
        if(response){
            setLoadingSubmit(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-success"
            }))
            navigate("/orders")
            getCart()
        }else{
            setLoadingSubmit(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
        }
    }
    return ( loading?<div className='min-h-96  w-full flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

        </div>: <div>
        <h2 className="flex justify-center pb-3">My Cart</h2>
        <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="cart-info mb-5">
            
            <div className="cart-info-content p-10">
                <h2 className="pb-2"> #{cart.cart.id ? cart.cart.id : "-"}  Cart </h2>
                <div>
                    <p className="flex"> <div className="title">Items </div>: {cart.items.length} </p>
                    {/* <p className="flex"> <div className="title">Total </div>: {totalCart }  </p> */}
                </div>
                <div className="flex justify-center pt-5">
                    <button className="flex justify-center items-center w-full" onClick={checkout} disabled={!localStorage.getItem("user") || loadingSubmit}>
                        {loadingSubmit ?<div className="loader"></div>:"Checkout"}
                    </button>
                </div>
            </div>
            
        </div>
        <div className="">
            {cart.items.length > 0 ? cart.items.map((item,index)=>(<CartCard updateItem={(id,value,type,i,item )=>{
                updateItem(id,value,type,i,item )
            }} deleteItem={(id,name)=>{
                    deleteItem(id,name)
            }}  minimum_sale={item.pivot?.Minimum_sale}  index={index} item={item} key={`Item_Cart_${item.name?item.name:"-"}`} />))
            : <div className='min-h-96 w-full '>
 
            <div className="flex justify-center w-full">
                <img alt="no Items" src={sad} />
            </div>
            <h5 className="flex justify-center py-2" >No Items </h5>

            </div> }

        </div>
    </div>
        </div>
        
        
         );
}

export default Cart;