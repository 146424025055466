import DataTable from 'react-data-table-component';
import FilterHeader from '../requests/filterHeader';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { alert } from '../../redux/actions/alert';
// import AddNewItems from './addNewItems';
import { Helper } from '../../functionality/helper';
import { apiRoutes } from '../../functionality/apiRoutes';
import { format } from 'date-fns';
import CustomPagination from '../../components/layouts/customPagination';
import Select from 'react-select';
import ConfirmDeleted from "../../components/layouts/confirm";
import { MdOutlineDelete } from "react-icons/md";
import { notification } from "../../redux/actions/notification";
import Budge from '../../components/layouts/budge';
import { MdModeEdit } from "react-icons/md";

function Order() {
      const [searchParams,setSearchParams] = useSearchParams();
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const [data ,setData ] = useState([]) 
      const page = useRef(1)
      const totalPage = useRef(0)
      const size = useRef(5)
      const status = useRef("")
      const directionRef = useRef("desc")
      const columnRef = useRef("id")
      const [loading , setLoading] = useState(true)

      const controllerRef = useRef()
      // Columns configuration
      const columns = [
        {
          name: 'Action',
          selector: row => <div style={{ minWidth: '220px' }} className='flex gap-2'>
            <div className="icon-success  cursor-pointer " onClick={()=>{navigate(`/order/${row.id}`)}} ><GrView/></div>
            <div className={`icon-div ${row.status == 0?"icon-div cursor-pointer ":" icon-div !bg-neutral-400  cursor-not-allowed "}`} onClick={()=>{
              if(row.status == 0)
                navigate(`/order/edit/${row.id}`)
            }} ><MdModeEdit/></div>
            <div className={`${row.deletable == 1 ? "icon-error cursor-pointer ":"icon-div !bg-red-400 cursor-not-allowed"}`}  onClick={()=>{
              if(row.deletable == 1)
                dispatch(alert({
                  isOpen : true,
                  component : <ConfirmDeleted onClick={()=>removeItem(row.id)} loading={false} message={`Are you sure delete ( #${row.id} ).`} label={"Confirm"} />
                }))
            }}><MdOutlineDelete style={{fontSize:"15px"}}/></div>
          </div>,
          sortable: false, // No sorting for this column
        },
        {
          id : "id",
          name: 'ID',
          selector: row => row.id,
          sortable: false, // Allows sorting by this column
        },
        {
          id : "total",
          name: 'Total',
          selector: row => row.total,
          sortable: true,
        },

          {
            id : "items_count",
            name: 'Items',
            selector: row => row.content,
            sortable: true, // No sorting for this column
          },
          {
            id : "order_date",
            name: 'Date',
            selector: row => row.date,
            sortable: true, // No sorting for this column
          },
          {
            id : "status",
            name: 'Status',
            selector: row => <Budge type="group"  status={row.status} />,
            sortable: false, // No sorting for this column
          },
         
      ];
      
      // Custom Styles
      const customStyles = {
        headCells: {
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#f3f4f6',
            color: '#4b5563',
          },
        },
        rows: {
          style: {
            fontSize: '14px',
          },
        },
      };
      const removeItem = async (id) =>{
        dispatch(alert({
          isOpen : true,
          component : <ConfirmDeleted  loading={true} message={`Are you sure delete ( #${id} ).`} label={"Confirm"} />
        }))
        const { response , message } = await Helper({
          url : apiRoutes.order.delete(id),
          hasToken : true,
          method : "DELETE"
        })
        if(response){
          getOrderFun()
          dispatch(notification({
            isOpen : true,
            bg_color : "bg-success",
            message : message
        }))
          dispatch(alert({
            isOpen : false,
            component : <></>
          }))
        }else{
          dispatch(notification({
            isOpen : true,
            bg_color : "bg-error",
            message : message
        }))
          dispatch(alert({
            isOpen : false,
            component : <></>
          }))
        }
      }
      const resetFilter  =  ()=>{
        setSearchParams(new URLSearchParams()); // Clear all parameters
      }
      const handleSort = (column, direction) => {
        if(column.id){
          columnRef.current = column.id
          directionRef.current = direction;
          searchParams.set("direction",directionRef.current)  
          searchParams.set("column",columnRef.current) 
          setSearchParams(searchParams)
        }
      };
      const getOrder= async (pageParameter = 1 , sizeParameter = 5 , searchName = "")=>{
       setData([])
       if(controllerRef.current){
          controllerRef.current.abort()
       }
       let params = {
        per_page : sizeParameter,
        page_number : pageParameter ,
       }
       if(status.current != null && status.current != ""  )
        params["status"] = status.current
       if(directionRef.current ) 
        params["sorting_type"] = directionRef.current
       if(columnRef.current)
        params["sortable_column"] = columnRef.current

       controllerRef.current =new AbortController()
       const signal = controllerRef.current.signal
        const {response ,message } = await Helper({
          url : apiRoutes.order.all,
          method : "GET",
          hasToken : true,
          signal,
          params
        })
        if(response){
          setLoading(false)
          const newData = response.data.map((element) => ({
            id: element.id,
            total: element.total  ? element.total : "-",
            date: element.order_date ? format(element.order_date, 'dd/MM/yyyy') : "-",
            content: element.items_count ? element.items_count : 0,
            status: element.status != null ? element.status : 0,
            deletable : element.deletable ? element.deletable : 0,
          }));
         
          setData((prev) => [...prev, ...newData]);
          totalPage.current = response.meta.total
          
        }else{
          setLoading(false)
          console.log(message);
          
        }
      }
      useEffect(() => {
        if(!localStorage.getItem("user"))
          navigate("/")
       
        getOrderFun()
      }, [searchParams]);  
      const getOrderFun = ()=>{
        status.current = searchParams.get("status") ? searchParams.get("status") : "" 
        directionRef.current = searchParams.get("direction") ? searchParams.get("direction") : ""
        columnRef.current = searchParams.get("column") ? searchParams.get("column") : ""
        page.current = searchParams.get("page") ? searchParams.get("page") : 1
        size.current = searchParams.get("size") ? searchParams.get("size") : 5
        searchParams.set("page",page.current) 
        searchParams.set("size",size.current) 
        getOrder(page.current,  size.current); // Fetch data based on URL params
      }
      const sizeArray = [{label : 5,value:5},{label : 10,value:10},{label : 50,value:50}]
     

      

    return ( loading?<div className='min-h-96 flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

    </div>:<div style={{ padding: '10px' }}>
      {
        data.length>0 && <FilterHeader isSearchName={false} id={status.current} type="item" reset={resetFilter} returnedIdValue={(res)=>{
          searchParams.set("page",1)
          searchParams.set("status",res)    
          setSearchParams(searchParams)

      }} />
      }
        
        <h2 className="flex justify-center pb-3">My Order</h2>
        <DataTable 
          highlightOnHover // Highlights rows on hover
          customStyles={customStyles} // Applies custom styles
          columns={columns}
          data={data}
          onSort={handleSort}
           // Page size options
        />
        <div className='flex items-center justify-center mt-5 lg:justify-end gap-2'>
            <div className='flex items-center justify-center'>
              <Select options={sizeArray}
                onChange={(option)=>{
                  searchParams.set("page",1)  
                  searchParams.set("size",option.value)  
                  setSearchParams(searchParams)
                  
                }}
                value={sizeArray.find(option => option.value == size.current)}
              />
            </div>
            <CustomPagination
              currentPage={parseInt(page.current)}
              rowsPerPage={parseInt(size.current)}
              rowCount={parseInt(totalPage.current)}
              onSort={handleSort}
              onChangePage={(newPage) => {
                 searchParams.set("page",newPage)  
                 setSearchParams(searchParams)
            }}
            />
            
        </div>
        
      </div>);
}

export default Order;