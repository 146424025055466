

import { useEffect, useRef, useState } from "react";
import { getBrands, getCategory } from "../../functionality/functions";
import FilterSidebar from "./filterSideBar";
import { useSearchParams } from "react-router-dom";
import { MdFilterAlt } from "react-icons/md";

import Card from "../../components/layouts/card";
import Paginator from "../../components/layouts/paginator"
import { Helper } from "../../functionality/helper"
import { apiRoutes, imageHost } from "../../functionality/apiRoutes"

import sad from "../../images/sad.png"

function Items() {
    const [ items , setItems ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ brands, setBrands ] = useState([])
    const [ open, setOpen ] = useState(false)
    const [ lastPage, setLastPage ] = useState(1)
    const [ loading, setLoading ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [searchParams,setSearchParams] = useSearchParams();
    const star = useRef("")
    const brand_id = useRef("")
    const category_id = useRef('')
    const is_new = useRef("")
    const name = useRef("")
    const sortable_column = useRef("")
    const sorting_type = useRef("")
    const page_number = useRef(1)
    const per_page = useRef(12)
    const search = useRef("")
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getCategories(signal)
        getBrand(signal)
        return ()=> controller.abort()
    },[])
    useEffect(()=>{
            star.current = searchParams.get("star") ? searchParams.get("star") : ""
            is_new.current =  searchParams.get("is_new") ? searchParams.get("is_new") : ""
            brand_id.current = searchParams.get("brand_id") ? searchParams.get("brand_id") : ''
            category_id.current = searchParams.get("category_id") ? searchParams.get("category_id") : ''
            name.current = searchParams.get("name") ? searchParams.get("name") : ''
            page_number.current = searchParams.get("page") ? searchParams.get("page") : 1
            per_page.current = searchParams.get("size") ? searchParams.get("size") : 12
            sortable_column.current = searchParams.get("sortable_column") ? searchParams.get("sortable_column") : ""
            sorting_type.current = searchParams.get("sorting_type") ? searchParams.get("sorting_type") : ""
            searchParams.set("page",page_number.current) 
            searchParams.set("size",per_page.current) 
            search.current = searchParams.get("search") ? searchParams.get("search") : "" 

            if(star.current != "" || is_new.current != "" || brand_id.current != "" || category_id.current != "" || name.current != ""  ){
                search.current = ""  
                searchParams.delete("search") 
            }
            
            setSearchParams(searchParams) 
            getItems (page_number.current,  per_page.current ) 
    },[searchParams])
    const getItems = async (pageParameter = 1 , sizeParameter = 12 ,)=>{
        setLoading(true)
        setItems([])

        let params = {
            per_page : sizeParameter,
            page_number : pageParameter ,
           }
           
           if(name.current.length )
            params["name"] = name.current
           if(star.current != null && star.current != "" )
            params["star"] = star.current
           if(brand_id.current != null && brand_id.current != "" )
            params["brand_id"] = brand_id.current
           if(category_id.current != null && category_id.current != "" )
            params["category_id"] = category_id.current
           if(is_new.current != null && is_new.current != "" )
            params["is_new"] = is_new.current
         
            if((is_new.current != null && is_new.current != "" ) || (name.current.length > 2) || (star.current != null && star.current != "") 
                 || (brand_id.current != null && brand_id.current != "") ||     (category_id.current != null && category_id.current != "" ) ){
                searchParams.delete("sorting_type")
                searchParams.delete("sortable_column")
                setSearchParams(searchParams)
            }else{
                if(sorting_type.current )
                    params["sorting_type"] = sorting_type.current
                if(sortable_column.current)
                    params["sortable_column"] = sortable_column.current
            }
           
        let route = ""
        if(search.current.length != ""){
            params["search"] = search.current
            route = apiRoutes.home.generalSearch
        }else{
            route = apiRoutes.home.advancedSearch
        }
        const { response , message } = await Helper({
            url : route,
            method : "GET",
            params
        })
        if(response){
            setItems(response.data)
            setLoading(false)
            setLastPage(response.meta.last_page)
            setTotal(response.meta.total)
        }

    }
    const getCategories = async (signal)=>{
        const {res,message} = await getCategory(signal)
        if(res){
            setCategories(res.data)
        }
    }
    const getBrand = async (signal)=>{
        const {res,message} = await getBrands(signal)
        if(res){
            setBrands(res.data)
        }
    }
    return ( <div className="flex item ">
        <div className={`sidebar-items ${open && "open"} px-2  md:px-5`}>
            <div className="content-sidebar">
                
                <FilterSidebar returnedOpen={(res)=>setOpen(res)}  brands={brands} />
            </div>
            
        </div>
        <div className={`content-items px-5`}>
            <div className="pb-2 flex items-center justify-between">
                <h2 >Items ({total})</h2>
                <div className="lg:hidden icon-div cursor-pointer" onClick={()=>setOpen(true)}>
                    <MdFilterAlt/>
                </div>
            </div>
            {loading?  <div className='min-h-96 w-full flex justify-center items-center'>
 
            <div className="bounce"></div>
            <div className="bounce"></div>
            <div className="bounce"></div>

            </div>:items.length>0 ?<div className="flex grid  grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 lg:grid-cols-6 gap-2">
            { items.map((e,index)=>{
                    
                    return  <div key={`Items_list_with_filter_${e.id}`}  >
                            <Card visitor={e.visitors ? e.visitors : 0 } category={e.category_name?e.category_name:""} brand={e.brand_name?e.brand_name:""} quantity={e.amount != null ? e.amount : "-"} star={e.star != null ? e.star : 0}  id={e.id} image={e.image?`${imageHost + e.image}`:""}
                             status={e.is_new != null ? e.is_new : ""} className={e.is_new == "0" ?"bg-success":"bg-error"} title={e.name ? e.name : ""} price={e.price != null && e.commission != null ? (parseFloat(e.price) + parseFloat(e.commission)) +" " + e.coin?.symbol :"-"}
                             oldPrice={e.oldPrice?e.oldPrice:""}/>
                        </div>
                })
                
                }
            </div>: <div className='min-h-96 w-full '>
 
                <div className="flex justify-center w-full">
                    <img alt="no Items" src={sad} />
                </div>
                <h5 className="flex justify-center py-2" >No Items </h5>

                </div>}
            
            <div>
                <Paginator countPages={parseInt(lastPage)}
                currentPage={parseFloat(page_number.current)}
                onPageChange={(res)=>{
                    searchParams.set("page",res) 
                    setSearchParams(searchParams)  
                }}
            />
            </div>

        </div>

        
    </div> );
}

export default Items;