import { Outlet } from "react-router-dom";
import NavBar from "../components/layouts/navbar";
import Footer from "../components/sections/footer";



function Layout() {

    return ( <div>
        <NavBar/>
        <div className="min-h-96 pt-32">
            <Outlet/>
        </div>
        <Footer/>
        
    </div> );
}

export default Layout;