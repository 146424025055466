import React,{ useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkUserLogedIn } from "./functionality/functions";

const AuthChecker = ({ children }) => {
  const location = useLocation();

  // Track if the token check has already been done
  const hasCheckedTokenRef = React.useRef(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Run token check only if it hasn't been done yet or if the route changes
    const checkToken = async () => {
      if (!hasCheckedTokenRef.current) {
        try {
          const { response } = await checkUserLogedIn(signal);
          if (response && !response.success) {
            if(localStorage.getItem("user")){
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.href = "/";
            }
          }
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Error checking token:", error);
          }
        }
        hasCheckedTokenRef.current = true; // Mark as checked after the first check
      }
    };

    checkToken();

    // Cleanup the abort controller
    return () => controller.abort();
  }, [location]); // Runs on route change or initial mount

  return children;
};

export default AuthChecker;