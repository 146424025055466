import { BrowserRouter, Route, Routes } from 'react-router-dom';
// Components
import Layout from './pages/layout';
import LandingPage from './pages/landingPage/page';
import NotFound from './pages/notFound/page';
import RequestsAds from './pages/requests/requestsAds';
import LayoutRequests from './pages/requests/layout';
import Requests from './pages/requests/requests';
import DetailsRequest from './pages/requests/detailsRequest';
import DetailsItems from './pages/items/item';
import ViewAndEditItem from './pages/items/viewAndEdit';
import Items from './pages/items/page';
import AuthChecker from './authChecker';
import Cart from './pages/cart/cart';
import DynamicPages from './pages/daynamicPages/page';
import Order from './pages/order/page';
import ItemsOrder from './pages/order/itemsOrder';
import EditOrder from './pages/order/edit';

function App() {
  return (
    <BrowserRouter>
      <AuthChecker>
      <Routes>
        <Route element={<Layout/>} path='/*' >
            <Route element={<LandingPage/>} path="" />
            <Route element={<DetailsItems/>} path="item/:id" />
            <Route element={<Items/>} path="items" />
            <Route element={<Order/>} path='orders' />
            <Route element={<ItemsOrder/>} path='order/:id' />
            <Route element={<EditOrder/>} path='order/edit/:id' />
            <Route element={<LayoutRequests/>} path="requests/*" >
                <Route element={<ViewAndEditItem/>} path=":group_id/:id/:name" />
                <Route element={<DetailsRequest/>} path=":id" />
                <Route element={<Requests/>} path="" />
                <Route element={<RequestsAds/>} path="add" />
                
            </Route>
            <Route element={<Cart/>} path="cart" />
            <Route element={<DynamicPages/>} path="page/:id" />
            <Route element={<NotFound/>} path="*"/>
        </Route>
      </Routes>
      </AuthChecker>
    
    </BrowserRouter>
  );
}

export default App;
