import { useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import Budge from '../../components/layouts/budge';
import FilterHeader from './filterHeader';
import { useEffect, useState ,useRef} from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helper } from '../../functionality/helper';
import { apiRoutes, imageHost } from '../../functionality/apiRoutes';
import { format } from 'date-fns';
import CustomPagination from '../../components/layouts/customPagination';
import Select from 'react-select';
import GroupInfoCard from "./detailsGroupInfo";
// Icons
import { IoStar } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { MdModeEdit } from "react-icons/md";
import { MdOutlineDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { alert } from "../../redux/actions/alert";
import ConfirmDeleted from "../../components/layouts/confirm";
import { notification } from "../../redux/actions/notification";


function DetailsRequest() {
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const [searchParams,setSearchParams] = useSearchParams();
      const { id } = useParams()
      const [data ,setData ] = useState([]) 
      const page = useRef(1)
      const totalPage = useRef(1)
      const size = useRef(5)
      const name = useRef("")
      const status = useRef(null)
      const directionRef = useRef("desc")
      const columnRef = useRef("id")
      const [loading , setLoading] = useState(true)

      // Columns configuration
      const columns = [
        {
          name: 'Action',
          selector: row => <div className='flex gap-2'>
            <div className={`icon-div ${row.status == 0?"icon-div cursor-pointer ":" icon-div !bg-neutral-400  cursor-not-allowed "}`} onClick={()=>{
              if(row.status == 0)
                navigate(`/requests/${id}/${row.id}/edit`)
            }} ><MdModeEdit/></div>
          
           <div className="icon-success cursor-pointer " onClick={()=>{
              if(row.status == 0){
                navigate(`/requests/${id}/${row.id}/view`)
              }else{
                navigate(`/item/${row.id}`)
              }
              
              
              }} ><GrView style={{fontSize:"15px"}}/></div>
            {<div className={`${row.status == 0 ?"icon-error cursor-pointer ":"icon-div !bg-red-400 cursor-not-allowed "}`} onClick={()=>{
              if(row.status == 0 )
              dispatch(alert({
                isOpen : true,
                component : <ConfirmDeleted onClick={()=>removeItem(row.id,row.name)} loading={false} message={`Are you sure delete ( ${row.name} ) .`} label={"Confirm"} />
              }))
            }}><MdOutlineDelete style={{fontSize:"15px"}}/></div>}
        </div>,
          width: "120px",
          sortable: false, // No sorting for this column
        },
        {
          id: "id",
          name: 'ID',
          selector: row => row.id,
          sortable: true, // Allows sorting by this column
        },
        {
          name: 'Photo',
          selector: row => <img className="rounded h-10 w-10 object-contain" alt={row.name} src={row.img} />,
          sortable: true,
        },
        {
          id: "name",
          name: 'Name ',
          selector: row => row.name,
          sortable: true,
        },
        {
            id: "brand_id",
            name: 'Brand',
            selector: row => row.brand,
            sortable: true, // No sorting for this column
          },
        {
            id: "category_id",
            name: 'Category',
            selector: row => row.category,
            sortable: true,
          },
         
          {
            id: "amount",
            name: 'Amount',
            selector: row => row.amount,
            sortable: true, // No sorting for this column
          },
          {
            id: "price",
            name: 'Price ',
            selector: row => row.price,
            sortable: true,
          },
          {
            id: "total",
            name: 'Total ',
            selector: row => row.total,
            sortable: true, // No sorting for this column
          },
          {
            id: "currency",
            name: 'Currency ',
            selector: row => row.coin,
            sortable: false, // No sorting for this column
          },
          {
            id : "visitors",
            name: 'Visitors',
            selector: row => row.visitors,
            sortable: true, // No sorting for this column
          },
          {
            id : "star",
            name: 'Star',
            selector: row => <div><IoStar className={`${row.rate?"text-warning":"text-zinc-700"}`} /></div> ,
            sortable: true, // No sorting for this column
          },
          {
            id : "created_at",
            name: 'Date',
            selector: row => row.date,
            sortable: true, // No sorting for this column
          },
          {
            id : "is_new",
            name: 'New',
            selector: row => <Budge type="item"  status={row.is_new} />,
            sortable: false, // No sorting for this column
          },
          {
            id : "status",
            name: 'Status',
            selector: row => <Budge type="group"  status={row.status} />,
            sortable: false, // No sorting for this column
          },
          
      ];
      
      // Custom Styles
      const customStyles = {
        headCells: {
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#f3f4f6',
            color: '#4b5563',
          },
        },
        rows: {
          style: {
            fontSize: '14px',
          },
        },
      };
    const handleSort = (column, direction) => {
        if(column.id){
          columnRef.current = column.id
          directionRef.current = direction;
          searchParams.set("direction",directionRef.current)  
          searchParams.set("column",columnRef.current) 
          setSearchParams(searchParams)
        }
      };
      const resetFilter  =  ()=>{
        setSearchParams(new URLSearchParams()); // Clear all parameters
      }

      const controllerRef = useRef()
      const removeItem = async (id,name) =>{
        dispatch(alert({
          isOpen : true,
          component : <ConfirmDeleted  loading={true} message={`Are you sure delete ( ${name} ) .`} label={"Confirm"} />
        }))
        const { response , message } = await Helper({
          url : apiRoutes.item.delete(id),
          hasToken : true,
          method : "DELETE"
        })
        if(response){
          getitemsFun()
          dispatch(notification({
            isOpen : true,
            bg_color : "bg-success",
            message : message
        }))
          dispatch(alert({
            isOpen : false,
            component : <></>
          }))
        }else{
          dispatch(notification({
            isOpen : true,
            bg_color : "bg-success",
            message : message
        }))
          dispatch(alert({
            isOpen : false,
            component : <></>
          }))
        }
      }
      const getItemsByGroupId = async (pageParameter = 1 , sizeParameter = 5 , searchName = "")=>{
       setData([])
       let route = ""
       if(controllerRef.current){
          controllerRef.current.abort()
       }
       route = apiRoutes.item.advanceSearch
       let params = {
        per_page : sizeParameter,
        page_number : pageParameter ,
        group_id : id
       }

       if(name.current.length > 2)
        params["name"] = name.current
       if(status.current != null && status.current != "" )
        params["status"] = status.current
       if(directionRef.current ) 
        params["sorting_type"] = directionRef.current
       if(columnRef.current)
        params["sortable_column"] = columnRef.current
      
          
       controllerRef.current =new AbortController()
       const signal = controllerRef.current.signal
        const {response ,message } = await Helper({
          url : route,
          method : "GET",
          hasToken : true,
          params,
          signal
        })
        if(response){

          const newData = response.data.map((element) => {
            
            return  { id: element.id,
              img: element.image ? imageHost + element.image : "", 
              name:  element.name ? element.name : "-", 
              price: element.price ? element.price : "-",
              brand : element.brand_name ?  element.brand_name : "-",
              category : element.category_name ? element.category_name : "-", 
              total: element.total ? element.total : "-" , 
              amount : element.amount ? element.amount : "-",
              date : element.created_at ? format(element.created_at, 'dd/MM/yyyy') : "-",
              is_new : element.is_new != null ? element.is_new : "-",
              rate : element.star != null ? element.star : "-",
              visitors : element.visitors != null ? element.visitors : "-",
              coin : element.coin.symbol  ? element.coin.symbol : "-",
              status:element.status != null ? element.status : "-"}
            });
        
          setData((prev) => [...prev, ...newData]);
          totalPage.current = response.meta.total
          setLoading(false)
        }else{
          console.log(message);
          
        }
      }
      useEffect(() => {
        getitemsFun()
      }, [searchParams]);  
      const getitemsFun  = ()=>{
        page.current = searchParams.get("page") ? searchParams.get("page") : 1
        size.current = searchParams.get("size") ? searchParams.get("size") : 5
        name.current = searchParams.get("name") ? searchParams.get("name") : ""
        status.current = searchParams.get("status") ? searchParams.get("status") : "" 
        directionRef.current = searchParams.get("direction") ? searchParams.get("direction") : "desc"
        columnRef.current = searchParams.get("column") ? searchParams.get("column") : "id"
        getItemsByGroupId(page.current,  size.current ,name.current); // Fetch data based on URL params
      }
      const sizeArray = [{label : 5,value:5},{label : 10,value:10},{label : 50,value:50}]


     
   
    return ( loading ? <div className='min-h-96 flex justify-center items-center'>
 
      <div className="bounce"></div>
      <div className="bounce"></div>
      <div className="bounce"></div>

  </div>: <div style={{ padding: '10px' }}>
        <GroupInfoCard returnedValue={(res)=>{
          if(res)
            getItemsByGroupId(page.current,  size.current ,name.current)
        }} id={id} />
        <FilterHeader type="item" 
        returnedValueSearch={(res)=>{
          if(res.length> 2 ){
            searchParams.set("name",res)  
            searchParams.set("page",1)  
            setSearchParams(searchParams)
          }else if( res.length == 0){
            searchParams.delete("name")  
            searchParams.set("page",1)  
            setSearchParams(searchParams) 
          }
       }} 
        inputSearch={name.current}
        reset={resetFilter} 
        id={status.current}  
        returnedIdValue={(res)=>{
          searchParams.set("page",1)  
          searchParams.set("status",res)  
          setSearchParams(searchParams)
        }}
                      />
        <DataTable 
          // title={}
          columns={columns}
          onSort={handleSort}
          data={data}
          highlightOnHover // Highlights rows on hover
          customStyles={customStyles} // Applies custom styles
        />

        <div className='flex items-center justify-center mt-5 lg:justify-end gap-2'>
            <div className='flex items-center justify-center'>
              <Select options={sizeArray}
                onChange={(option)=>{
                  searchParams.set("page",1)  
                  searchParams.set("size",option.value)  
                  setSearchParams(searchParams)
                  
                }}
                value={sizeArray.find(option => option.value == size.current)}
              />
            </div>
            <CustomPagination
              currentPage={parseInt(page.current)}
              rowsPerPage={parseInt(size.current)}
              rowCount={parseInt(totalPage.current)}
              onChangePage={(newPage) => {
                 searchParams.set("page",newPage)  
                 setSearchParams(searchParams)
            }}
            />
            
        </div>
      </div>);
}

export default DetailsRequest;