import React, { useState, useEffect } from 'react';
import InputSearch from './inputWithIcon';
import { LuRefreshCcw } from "react-icons/lu";

function CustomCaptcha({ onVerify ,isButtonClick=false}) {
  const [captcha, setCaptcha] = useState('');
  const [input, setInput] = useState('');
  const [isVerified, setIsVerified] = useState(-1);
  const [isButton, setIsButton] = useState(false);
  // Generate a random captcha
  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  
  // Generate captcha on component mount
  useEffect(() => {
    setCaptcha(generateCaptcha());
  }, []);
  useEffect(()=>{
    setIsButton(isButtonClick)
    if(isButtonClick) verifyCaptcha()
  },[isButtonClick])

  // Handle input change
  const handleInputChange = (e) => {
    setInput(e.target.value.toUpperCase());
  };

  // Verify captcha
  const verifyCaptcha = () => {
    setIsButton(false)
    if (input === captcha) {
      setIsVerified(1);
      onVerify(true); // Callback to parent component
    } else {
      setIsVerified(0)
      setInput('');
      setCaptcha(generateCaptcha()); // Generate a new captcha
    }
  };
  // Handle key press (for Enter key)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      verifyCaptcha();
    }
  };

  // Handle input blur
  const handleBlur = () => {
    if(!isButton) verifyCaptcha();
    
  };
  return (
    <div >
      <p>Please enter the following characters:</p>
      <div className='flex justify-between'>
        <div className='flex gap-2 items-center ' style={{ fontSize: '24px', fontWeight: 'bold' }}>{captcha}</div>
        <div className='flex items-center cursor-pointer' onClick={()=>{
          setCaptcha(generateCaptcha())
          setInput("")
          setIsVerified(-1);
        }} style={{fontSize: '26px'}}><LuRefreshCcw/></div>
      </div>
      
      <div className='py-2'>
        <InputSearch
            value={input}
            type="text"
            onChange={handleInputChange}
            onKeyPress={handleKeyPress} // Handle Enter key
            onBlur={handleBlur} // Handle blur event
            placeholder="Enter CAPTCHA"
        />
      </div>
      <div >
      {isVerified == 0? <p className='text-error '>Incorrect CAPTCHA, please try again.</p>:(isVerified == 1 ? <p className='text-success'>CAPTCHA Verified!</p>:"")}
      </div>
      
    </div>
  );
}

export default CustomCaptcha;