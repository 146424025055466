function Budge({status,type,...props}) {

    return ( <div {...props} className={`${type == "item" ?( status == 0 ? "bg-success":"bg-error" ):status == 0 ? "bg-warning" :(status == 1 ? "bg-success": "bg-error" )} flex justify-center items-center p-1 rounded`}>
            {type == "item" && <>
                {status == 0 ? "New" : "Reused"}
            </>}
            {type == "group" && <>
                {status == 0 ?  "Pending" :(status == 1 ? "Accept" : "Rejected")}
                </>}
    </div> );
}

export default Budge;