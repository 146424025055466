import { imageHost } from "../../functionality/apiRoutes";
import { Link  } from "react-router-dom";
// ICONS
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineDelete } from "react-icons/md";


function CartCard({item,index,updateItem,deleteItem,minimum_sale,props}) {
    return ( <div {...props} className="cart-items p-5 mx-5">
                    <div onClick={()=>{deleteItem(item.id,item.name)}} className="icon-delete cursor-pointer icon-error"><MdOutlineDelete/></div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                        <div className="flex justify-center items-center">
                            <Link to={`/item/${item.id}`}>
                                <img alt={item.name ? item.name : "-"} src={item.image ? imageHost +item.image :"-"} />
                            </Link>
                            
                        </div>
                        <div className=" w-full">
                            <Link to={`/item/${item.id}`}>
                                <h3 className="pb-2">{item.name ? item.name : "-"}</h3>
                            </Link>
                           
                            <p dangerouslySetInnerHTML={{__html : item.description ? (item.description.length < 70?item.description:item.description.substring(0,70)+"..." ): "-" }}></p>
                            <p className="flex"> <div className="title">Price </div>: {item.price != null && item.commission != null ? (parseFloat(item.price) + parseFloat(item.commission)) +" " + item.coin?.symbol :"-"}</p>
                            
                            
                            <div className="flex justify-between items-center ">
                               <p className="flex"> <div className="title">Quantity </div>:  {item.pivot.amount}</p>
                               <div>
                                <div className="flex quantity justify-center sm:justify-end">
                                    <div className="flex justify-center items-center cursor-pointer" onClick={()=>{
                                        if(item.pivot.amount>item.pivot.Minimum_sale){
                                            updateItem(item.pivot.item_id,item.pivot.amount-1,"min",index,item)
                                        }
                                    }}>
                                        <FaMinus/>
                                    </div>
                                    <div style={{fontSize:"15px"}} className="flex number-quantity font-bold justify-center items-center w-8">
                                        {item.pivot.amount}
                                    </div>
                                    <div className="flex justify-center items-center cursor-pointer" onClick={()=>{
                                        if(item.pivot.amount<item.amount) { 
                                            updateItem(item.pivot.item_id,item.pivot.amount+1,"plus",index,item )
                                        }
                                    }}>
                                        <FaPlus/>
                                    </div>
                                
                                </div>
                               </div>
                            </div>
                            
                            <p className="flex"> <div className="title">Total </div>: {item.pivot.price && item.pivot.amount ?((parseFloat(item.pivot.price)+ parseFloat(item.commission) )* parseFloat(item.pivot.amount))+" "+  item.coin?.symbol  : "-"}</p>
                            <div className=" text-sm">
                                Minimum sales quantity is <strong>{minimum_sale}</strong>.
                            </div>
                            <p className="flex available text-zinc-600 "> <div  className="title">Available Quantity </div>: {parseFloat(item.amount)-parseFloat(item.pivot.amount)}</p>
                        </div>
                     </div>   
                    
    
                </div> );
}

export default CartCard;