import { useEffect, useRef, useState } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useSearchParams } from "react-router-dom";
function FetchWithScroll({type}) {
    const observerRef = useRef(null); // Ref for the observer trigger element
   
    const [categories , setCategories ] = useState([])
    const [brands , setBrands ] = useState([])
    const [loading_cat, setLoading_cat] = useState(false)
    const [loading_brand, setLoading_brand] = useState(false)
    const [ values, setValues]= useState({
        category_id : "",
        brand_id : "",
    })
    const [searchParams,setSearchParams] = useSearchParams();

    useEffect(()=>{
        setValues({
            brand_id : searchParams.get("brand_id") ? searchParams.get("brand_id") : '',
            category_id : searchParams.get("category_id") ? searchParams.get("category_id") : ''
        })
    },[searchParams])
    // Simulate fetching data
    const page_cat = useRef(0)
    const page_brand = useRef(0)
    const page_last_cat = useRef(1)
    const page_last_brand = useRef(1)
    const fetchData = () => {
        if(type == "brand"){
           page_brand.current +=1
            getBrands(page_brand.current)
        }else{
            page_cat.current +=1
            getCategories(page_cat.current)
        }

    };
  
    // Use IntersectionObserver to trigger fetching
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loading_cat && !loading_brand) {
            console.log("lllll");
            
            fetchData(); // Trigger fetch when the trigger element is visible
          }
        },
        {
          root: null, // Observe within the viewport
          rootMargin: "100px 0px",
          threshold: 0.5, // // Trigger when the target is fully visible
        }
      );
  
      if (observerRef.current) {
        observer.observe(observerRef.current);
      }
  
      return () => {
        if (observerRef.current) observer.unobserve(observerRef.current); // Cleanup
      };
    }, []);
    const handleChacked = (type,value,id) => {
        if(type == "brand_id"){
            setValues(prev=>({...prev,brand_id:id}))
            searchParams.set("brand_id",id)      
        }else{  
            setValues(prev=>({...prev,category_id:id}))
            searchParams.set("category_id",id) 
        }
        searchParams.set("page",1) 
        setSearchParams(searchParams)
    }
    const getCategories = async(pageParameter=1,signal) =>{
        if(page_cat.current <= page_last_cat.current ){
            setLoading_cat(true)
            const { response , message } = await Helper({
                url : apiRoutes.home.allCategoryWithCount(5,pageParameter),
                method : "GET",
                signal
    
            }) 
            if(response){
                setCategories(prev=>[...prev, ...response.data])
                page_last_cat.current = response.meta.last_page
                setLoading_cat(false);
            }
        }
      
        
    }
    const getBrands = async(pageParameter=1,signal) =>{
        if(page_brand.current <= page_last_brand.current){
            setLoading_brand(true)
            const { response , message } = await Helper({
                url : apiRoutes.home.allBrandWithCount(5,pageParameter),
                method : "GET",
                signal
    
            }) 
            if(response){
                setBrands(prev=>[...prev, ...response.data])
                page_last_brand.current = response.meta.last_page
                setLoading_brand(false)
            }
        }
    }
    return (<div className="scroll-div-with-fetch">
         <div className="pl-5">
                {type == "brand" && brands.map((brand,i)=>(<div className="flex gap-3" key={`Brand_Filter_${brand.name?brand.name:""}`}>
                    <div><input checked={values.brand_id == brand.id} onChange={(e)=>handleChacked("brand_id",e.target.checked,brand.id)} type="checkbox" className="custom-checkbox" /></div>
                    <div>{brand.name}{""}{`(${brand.items_count} )`}</div>

                </div>))}
                {type == "category" && categories.map((cat,i)=>(<div className="flex gap-3" key={`category_Filter_${cat.name?cat.name:""}`}>
                    <div><input checked={values.category_id == cat.id} onChange={(e)=>handleChacked("category_id",e.target.checked,cat.id)} type="checkbox" className="custom-checkbox" /></div>
                    <div>{cat.name}{""}{`(${cat.items_count} )`}</div>

                </div>))}

                
            </div>
        <div ref={observerRef} style={{ height: "20px" }}>
            {loading_cat && <div className="flex ">Loading
                        <div >
                            <div className="bounce-small"></div>
                            <div className="bounce-small"></div>
                            <div className="bounce-small"></div>
                        </div></div>}
            {loading_brand && <div className="flex">Loading
                <div >
                    <div className="bounce-small"></div>
                    <div className="bounce-small"></div>
                    <div className="bounce-small"></div>
                </div></div>}
        </div>
    </div>  );
}

export default FetchWithScroll;