import { useEffect, useState } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes, imgSrcBrand } from "../../functionality/apiRoutes";
import { useNavigate } from "react-router-dom";
import imgDefault from "../../images/defaultbrand.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Mousewheel, Keyboard } from 'swiper/modules';


function Brands() {
    const [ brands ,setBrands ] = useState([])
    const navigate = useNavigate()
    useEffect(()=>{
        setBrands([])
        const controller = new AbortController()
        const signal = controller.signal
        const getBrands = async (signal)=>{
            const { response, message } = await Helper({
                url : apiRoutes.home.allBrand,
                method : "GET",
                signal
            })
            if(response){
                response.data.forEach((ele)=>{
                    let str = ele.image ? ele.image : ""
                    let temp = str.split("/")
                    setBrands(prev=>([...prev, {...ele,
                        image : imgSrcBrand + temp[1] +"/"+temp[2]}]))
                })
                
            }
        }
        getBrands(signal)
        return () => controller.abort()
    },[])
    return ( <div className="brands px-5 md:px-10  lg:px-24">

        <div className="flex justify-center py-5">
            <h2 >Our Brands</h2>
        </div>
        <div>
        <Swiper
                cssMode={true}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
                keyboard={true}
                loop = {true}
                slidesPerView={6}
                modules={[ Mousewheel,Autoplay, Keyboard]}
                className="mySwiper "
                breakpoints={{
                    // When the screen width is >= 640px (mobile screens)
                    200: {
                        slidesPerView: 2, // Show 1 slide per view
                    },
                    // When the screen width is >= 640px (mobile screens)
                    500: {
                        slidesPerView: 3, // Show 1 slide per view
                    },
                    // When the screen width is >= 768px (tablet screens)
                    768: {
                        slidesPerView: 4, // Show 2 slides per view
                    },
                    // When the screen width is >= 1024px (medium to large screens)
                    1024: {
                        slidesPerView: 4, // Show 3 slides per view
                    },
                    // When the screen width is >= 1280px (large screens)
                    1280: {
                        slidesPerView: 6, // Show 4 slides per view
                    },
                }}
                >
                    {brands.length>0 && brands.map((e,index)=>{
                    
                        return <SwiperSlide key={"Brand_"+index } >
                            <div className=" card-image ">
                                <img className="cursor-pointer" onClick={()=>navigate(`/items?brand_id=${e.id}`)} alt={e.name ? e.name : ""} src={e.image ?  e.image : imgDefault} />
                                
                            </div>
                                
                            </SwiperSlide>
                    })}
                
            </Swiper>
        </div>
    
    </div> );
}

export default Brands;