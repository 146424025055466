import { useEffect, useState ,useRef} from 'react';
import { Helper } from '../../functionality/helper';
import { apiRoutes } from '../../functionality/apiRoutes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function OrderInfoCard({id}) {
    const [order , setOrder] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        getOrderDetails(signal)
        return ()=> controller.abort()
      }, []);  
    const getOrderDetails = async(signal)=>{
        const { response , message} = await Helper({
          url :  apiRoutes.order.getOne(id),
          hasToken : true,
          method : "GET",
          signal
        })
        if(response){
          setOrder(response.data)
  
        }
      }


    return ( <div className="p-5 group-info">
      
      { <h2 className="pb-2"> Order ID : # {id? id : "-"}   </h2>}
      { <p>It's items : {order.length}</p>}


    </div> );
}

export default OrderInfoCard;