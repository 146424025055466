import { useEffect, useState } from "react";
import { useParams,Link, useNavigate } from "react-router-dom";
import Budge from "../../components/layouts/budge";
import { Helper } from "../../functionality/helper";
import { apiRoutes, imageHost } from "../../functionality/apiRoutes";
// icons
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { IoStar } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { currency } from "../../functionality/data";
import { useDispatch } from "react-redux";
import { notification } from "../../redux/actions/notification";
import { getCartItems } from "../../functionality/functions";
import { cart } from "../../redux/actions/cart";
import { alert } from "../../redux/actions/alert";
import SignIn from "../auth/signIn";
import LatestItems from "../../components/sections/latestItems";



function DetailsItems() {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ item , setItem ] = useState({})
    const [ loading , setLoading ] = useState(true)
    const [ loadingSubmit , setLoadingSubmit ] = useState(false)
    const [ quantity , setQuantity ] = useState(1)
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        const getItem = async (signal)=>{
            const { response, message ,statusCode } = await Helper({
                url : apiRoutes.item.getOne(id),
                method : "GET",
                hasToken : true,
                signal
            })
            if(response){
                setLoading(false)
                setItem(response.data)
                setQuantity(response.data.Minimum_sale)
            }else{
                if(statusCode == 400){
                    navigate("notFound")
                }
                
            }
        }
        getItem(signal)
        window.scrollTo({ top: 0,  behavior: 'smooth' });
        return ()=> controller.abort()  
    },[id])
    const addToCart = async ()=>{
        setLoadingSubmit(true)
        const {response , message} = await Helper({
            url : apiRoutes.cart.addItem,
            method : "POST",
            hasToken : true,
            body :{
                item_id :id,
                amount : quantity
            }
        })
        if(response){
            setLoadingSubmit(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-success"
            }))
            const {res,msg} = await  getCartItems()
            if(res){
                localStorage.setItem("cart",res.data.items.length)
                dispatch(cart({
                    number : res.data.items.length
                }))
            }
        }else{
            setLoadingSubmit(false)
            dispatch(notification({
                isOpen : true,
                message : message,
                bg_color : "bg-error"
            }))
        }

    }
    return ( loading?<div className='min-h-96 flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>
  
    </div>: <div className="px-10">
        <div className="grid grid-cols-1 lg:grid-cols-2  ">
            {/* <div className=" bg-stone-400 relative">
                <div className="sticky">
                 <img className="w-full max-h-60 lg:max-h-96 object-contain" alt={item.name} src={item.image} />
                </div>
               
            </div> */}
            <div className=" relative">
                <div className="sticky  p-10 " style={{top:"0",right:"0"}}>
                    <img 
                        className="w-full max-h-60 lg:max-h-96 object-contain" 
                        alt={item.name? item.name : "Item Name"} 
                        src={item.image? imageHost+item.image : ""} 
                    />
                </div>
            </div>
        
            <div className="">
                <div className="flex justify-between pb-2">
                    <h2 className="font-bold">{item.name ? item.name : ""}</h2>
                    <div className="flex gap-1">
                        <div className="flex  items-center"><IoStar className={`${item.star != null ?(item.star == 0?"text-zinc-700":"text-warning"):""}`} /></div>
                        <div   className="flex gap-1  items-center ">
                                <GrView /> <span style={{fontSize:"13px"}}>{item.visitors}</span>
                            </div>

                    </div>
                </div>
                
                <div className="flex justify-between">
                    <div style={{fontSize:"20px"}} className="text-error font-bold">
                        {item.price != null && item.commission != null ? (parseFloat(item.price) + parseFloat(item.commission)) +" " + item.coin?.symbol :"-"}
                    </div>
                    <div style={{width:"fit-content"}}>
                        <Budge type={"item"} status={item.is_new} />
                    </div>
                </div>
                <ul>
                    <li className="flex"><div className="min-w-20 ">Brand :</div> {item.brand_name?item.brand_name:"-"}</li>
                    <li className="flex"> <div className="min-w-20 ">Category :</div> {item.category_name?item.category_name:"-"}</li>
                </ul>
                
             
                <div>
                    <h3 className="font-bold py-2">Description :</h3>
                    <p dangerouslySetInnerHTML={{__html : item.description? item.description :"-"}}></p>
                </div>
                <div className="flex grid grid-cols-1 sm:grid-cols-3 gap-3 py-2 ">
                    <h5 className="font-bold flex  items-center ">
                        Quantity :  {item.amount?item.amount:"-"}
                    </h5>
                    <h5 className="font-bold flex  items-center ">
                        Total Price : {item.price != null && item.commission != null ?(parseFloat(item.price) + parseFloat(item.commission))* quantity :"-"}  {item.coin?.symbol}
                    </h5>
                    <div className="flex quantity justify-center sm:justify-end">
                        <div className="flex justify-center items-center cursor-pointer" onClick={()=>{
                            if(quantity>item.Minimum_sale) setQuantity(quantity-1)
                        }}>
                            <FaMinus/>
                        </div>
                        <div style={{fontSize:"20px"}} className="flex font-bold justify-center items-center w-10">
                            {quantity}
                        </div>
                        <div className="flex justify-center items-center cursor-pointer" onClick={()=>{
                            if(quantity<item.amount) setQuantity(quantity+1)
                        }}>
                            <FaPlus/>
                        </div>
                        
                    </div>
                   
                    
                </div>
                <div className="py-2">
                    Minimum sales quantity is <strong>{item.Minimum_sale}</strong>.
                </div>

                <div>
                        <button className="flex justify-center items-center w-full" onClick={addToCart} disabled={!localStorage.getItem("user") || loadingSubmit}>
                            {loadingSubmit ?<div className="loader"></div>:"Add To Cart"}
                            </button>
                        {!localStorage.getItem("user") && <p onClick={()=>{
                            dispatch(alert({
                                isOpen : true,
                                component : <SignIn/>
                            }))
                        }} className="py-1 cursor-pointer hint-text text-zinc-800 text-white rounded mt-3 text-center">You have to login in to start with us. </p>}
                </div>

                
            </div>

        </div>

        <div className="mt-10">
            <LatestItems/>
        </div>
    </div> );
}

export default DetailsItems;