import Card from "../layouts/card";
import { useEffect, useRef, useState } from 'react'
import FilterHeader from "../layouts/filterHeader"
import { Helper } from "../../functionality/helper"
import { apiRoutes, imageHost } from "../../functionality/apiRoutes"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import img from "../../images/sad_face.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { currency } from "../../functionality/data";



function Products() {
    const [loading,setLoading] = useState(false)
    const [opacity,setOpactity] = useState(false)
    const [searchParams,setSearchParams] = useSearchParams();
    const [ categories ,setCategories ] = useState([])
    const [ items ,setItems ] = useState(undefined)
    const  category_id = useRef("")
    const navigate = useNavigate()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        const getCategories = async (signal)=>{
            const { response, message } = await Helper({
                url : apiRoutes.home.allCategoryWithCount(20,1),
                method : "GET",
                hasToken : true,
                signal
            })
            if(response){
                setCategories(response.data)
                
            }
        }
        getCategories(signal)

        return () => controller.abort()
    },[])
    useEffect(()=>{
        category_id.current = searchParams.get("category_id") ? searchParams.get("category_id") : ''
        getItemsByIdCategory()
    },[searchParams])
    const getItemsByIdCategory = async ()=>{
        setLoading(true)
        setItems(undefined)

        let params = {
            per_page : 20,
            page_number : 1 ,
           }
           if(category_id.current != null && category_id.current != "" )
            params["category_id"] = category_id.current

        const { response , message } = await Helper({
            url : apiRoutes.home.advancedSearch,
            method : "GET",
            params
        })
        if(response){
            setItems(response.data)
            setLoading(false)
        }
    }
    return ( <div className="products py-5 ">
        <div className="flex justify-center">
            <h2>What's New</h2>
        </div>
        <div className="flex  justify-center w-full  pt-5">
            <div className="flex   justify-center w-full lg:w-1/2 pt-5">
                    <FilterHeader categories={categories} />
            </div>
        </div>
        {loading ?<div className='min-h-96 dynamic-pages w-full flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

        </div>:<div className={`px-5 md:px-5 lg:px-16 ${opacity?"is-opacity":""}`}>
            {items && items.length > 0 ?<Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={4}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper "
                breakpoints={{
                    // When the screen width is >= 640px (mobile screens)
                    200: {
                        slidesPerView: 1, // Show 1 slide per view
                    },
                    // When the screen width is >= 640px (mobile screens)
                    500: {
                        slidesPerView: 2, // Show 1 slide per view
                    },
                    // When the screen width is >= 768px (tablet screens)
                    768: {
                        slidesPerView: 3, // Show 2 slides per view
                    },
                    // When the screen width is >= 1024px (medium to large screens)
                    1024: {
                        slidesPerView: 3, // Show 3 slides per view
                    },
                    // When the screen width is >= 1280px (large screens)
                    1280: {
                        slidesPerView: 4, // Show 4 slides per view
                    },
                }}
                >
                    {items.length>0 && items.map((e,index)=>{
                    
                        return <SwiperSlide key={e.name+e.price+"category_list"+index} >
                            <div className="flex justify-center py-12 px-1 lg:px-4">
                                <Card visitor={e.visitors ? e.visitors : 0 } category={e.category_name?e.category_name:""} brand={e.brand_name?e.brand_name:""} quantity={e.amount != null ? e.amount : "-"} star={e.star != null ? e.star : 0}  id={e.id} image={e.image?`${imageHost + e.image}`:""}
                                    status={e.is_new != null ? e.is_new : ""} className={e.is_new == "0" ?"bg-success":"bg-error"} title={e.name ? e.name : ""} price={ e.price != null && e.commission != null ? (parseFloat(e.price) + parseFloat(e.commission)) +" " + e.coin?.symbol :"-"}
                                    oldPrice={e.oldPrice?e.oldPrice:""}/>
                            </div>
                                
                            </SwiperSlide>
                    })}
                    <SwiperSlide >
                        <div className="flex justify-center py-12 px-1 lg:px-4">
                          
                          <div className="shadow  p-3 flex justify-center items-center card-product-img">
                             <button onClick={()=>{
                                if(searchParams.get("category_id")){
                                    navigate(`/items?category_id=${searchParams.get("category_id")}&page=1&size=12`)
                                }else{
                                    navigate(`/items?page=1&size=12`)
                                }
                                
                             }}>See More</button></div>
                        </div>
                                
                    </SwiperSlide>
                
            </Swiper>:items? <div className='flex flex-col  items-center py-10 items-center'>
                <div>
                    <img className="w-40" alt="sad-image" src={img}  />
                </div>
                <h2 className="py-5">No Items Here</h2>
                </div> :""}
            

        </div>}
        
        


    </div> );
}

export default Products;