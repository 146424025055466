// export const Host = "https://api2.wemarketglobal.com/public/api"
export const Host = "https://staging.isnaadtech.com/public/api"
// FOR IMAGES
// export const imgSrcBrand = "https://api2.wemarketglobal.com/public/brand/"
// export const imageHost = "https://api2.wemarketglobal.com/public/item/"
// export const imgSrcCat = "https://api2.wemarketglobal.com/public/category/"
// export const imgPage = "https://api2.wemarketglobal.com/public/page/"
export const imgSrcBrand = "https://staging.isnaadtech.com/public/brand/"
export const imageHost = "https://staging.isnaadtech.com/public/item/"
export const imgSrcCat = "https://staging.isnaadtech.com/public/category/"
export const imgPage = "https://staging.isnaadtech.com/public/page/"
export  const apiRoutes = {
    check : `${Host}/check`,
    auth : {
        signIn : `${Host}/login`,
        signUp : `${Host}/sign-up`,
        logout : `${Host}/logout`,
        changePassword : (id) => (`${Host}/change-password/${id}`),
        sendEmailForResetPassword : `${Host}/reset-password-step-1`,
        verifyResetPassword : `${Host}/reset-password-step-2`,
        resendVerificationCode : `${Host}/resend-verification`,
        resetPassword : (id) => (`${Host}/reset-password-step-3/${id}`),
        verify : `${Host}/verify`,
        update : (id)=>(`${Host}/update/${id}`),
        sendVerificationCode : `${Host}/send-verification`,
        getInfoByEmail : (email)=>(`${Host}/get-By-Email/${email}`),
        getInfoById : (id)=>(`${Host}/get-User-By-Id/${id}`)
    },
    group : {
        add : `${Host}/add-group`,
        delete : (id)=>(`${Host}/delete-group/${id}`),
        all : (size,page)=>(`${Host}/get-groups-items/${size}/${page}`),
        getOne : (id)=>(`${Host}/get-group-by-id/${id}`),
        advanceSearch :  `${Host}/group-search`

    },
    item : {
        add : `${Host}/add-item`,
        delete : (id)=>(`${Host}/delete-item/${id}`),
        update : (id)=>(`${Host}/update-item/${id}`),
        updateImage : (id)=>(`${Host}/update-item-image/${id}`),
        getOneWithoutHome : (id,group_id)=>(`${Host}/get-item-by-id/${id}/${group_id}`),
        getOne : (id)=>(`${Host}/get-item-by-id-home/${id}`),
        advanceSearch :  `${Host}/advanced-search`
    },
    all :{
        category : `${Host}/get-all-categories` ,
        brand :  `${Host}/get-all-brands` 
    },
    dynamicPage : {
        getOne : (id)=>(`${Host}/get-page-by-id/${id}`)
    },
    home : {
        advancedSearch : `${Host}/advanced-search-home`,
        allCategory :`${Host}/get-all-categories-home`,
        allCategoryWithCount : (size,page)=>(`${Host}/all-category-with-count/desc/${size}/${page}`),
        allBrand : `${Host}/get-all-brands-home`, 
        allBrandWithCount : (size,page)=>(`${Host}/all-brands-with-count/desc/${size}/${page}`),
        itemsWithStar : `${Host}/all-items-with-star/20/1/star/desc`,
        latestItems : `${Host}/latest-items/1/20`,
        contactUs : `${Host}/contact-us`,
        itemsWithVistors : `${Host}/get-items-by-visitors-home`,
        generalSearch :  `${Host}/general-search`
    },
    cart :{
        getCart : `${Host}/get-cart-details` ,
        addItem : `${Host}/add-item-to-cart`,
        deleteItem : `${Host}/remove-item-from-cart`,
        updateItem :`${Host}/update-item-amout-in-cart`,
        chekout : `${Host}/checkout`
    },
    order :{
        all : `${Host}/get-orders` ,
        delete : (id)=>(`${Host}/delete-order/${id}`),
        update :  `${Host}/update-item-quantity-in-order` ,
        getOne : (id)=>(`${Host}/get-order-items/${id}`)
    },
    country : {
        all : `${Host}/get-country-code`
    },
    coins : {
        all : `${Host}/get-coins`
    }
}



