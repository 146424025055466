import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { alert } from "../../redux/actions/alert";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputSearch from "./inputWithIcon";
// icon
import { IoSearchOutline } from "react-icons/io5";
function SearchAlert({message,label,onClick,returnedValue}) {
    const dispatch = useDispatch()


    return ( <div className="p-5 confirm-card rounded">
        <div className="flex justify-end">
                
                <div onClick={()=>dispatch(alert({
                        isOpen : false,
                        component : <></>
                    }))} className="flex justify-end cursor-pointer py-1">
                        <AiOutlineClose />
                </div>
            </div>
        <h2 className="py-2">{message}</h2>
        <div className="pb-2">
                <InputSearch onChange={(res)=>returnedValue(res.target.value)}   icon={<IoSearchOutline/>} isDark={true} placeholder={"Search"} />
            </div>
        <div className="flex justify-center">
            <button className="flex justify-center items-center button-error" onClick={onClick} >
                {label}
            </button>
        </div>
    </div> );
}

export default SearchAlert