const CustomPagination = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
    const totalPages = Math.ceil(rowCount / rowsPerPage);
  
    const handlePrevious = () => {
      if (currentPage > 1) onChangePage(currentPage - 1);
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) onChangePage(currentPage + 1);
    };
  
    return (
      <div className="flex items-center justify-center gap-4 ">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className={`flex min-w-10 md:min-w-20  justify-center ${
            currentPage === 1 ? "bg-gray-300 text-dark   cursor-not-allowed" : "dark-bg text-white"
          }`}
        >
          &larr;<span className="hidden md:flex"> Previous</span>
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={` flex min-w-10 md:min-w-20 justify-center ${
            currentPage === totalPages ? "bg-gray-300 text-dark  cursor-not-allowed" : "dark-bg text-white"
          }`}
        >
          <span className="hidden md:flex"> Next</span> &rarr;
        </button>
      </div>
    );
  };


export default CustomPagination