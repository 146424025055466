import { useEffect, useState } from "react";

import { GrView,GrFormViewHide } from "react-icons/gr";

function InputSearch({icon,placeholder,width,type,isDark,onChange,register,onKey,value,isPassword,...props}) {
    const [ valueInput, setValueInput ] = useState(value)
    useEffect(()=>{
        setValueInput(value)
    },[value])
    const [ typeInput,setType ] = useState("")
    useEffect(()=>{setType(type)},[type])
    return ( <div className={isDark?"input w-full":"input-light w-full"}>
        <div className="icon-input">
            {icon}
        </div>
        <input onKeyDown={onKey} {...props} value={valueInput} type={typeInput}  {...register}  onChange={(e)=>{
                 if (register?.onChange) register.onChange(e);  // Call register's onChange
                 if (onChange) onChange(e);  // Call custom onChange if provided
                 setValueInput(e.target.value)
        }} placeholder={placeholder} />
        {isPassword &&  <div className="cursor-pointer" onClick={()=>{
            if(typeInput == "password"){
                setType("text")
            }else{
                setType("password") 
            }
        }} >
            { typeInput == "text" ? <GrFormViewHide/>  : <GrView/>}
            
        </div>}
       
        
        

    </div> );
}

export default InputSearch;