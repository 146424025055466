
const initialValues = {
    email: "",
    username: "",
    photo: "",
    password : "",
    mobile : "",
    fullName : "",
    id: ""
}

export const reducerUser = (state = initialValues , action)=>{
    switch(action.type){
        case "USER-SUBSCRIBE-INFORMATION":
            return{
                ...state,
                email: action.email,
                username: action.username,
                photo: action.photo,
                password : action.password,
                mobile : action.mobile,
                fullName : action.fullName,
                id: action.id
            }

        break;
        default :
            return { ...state }
            break;
    }
}