import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { alert } from "../../redux/actions/alert";
import { useEffect, useState } from "react";

function ConfirmDeleted({loading,message,label,onClick}) {
    const dispatch = useDispatch()
    
    const [loaderStatus, setLoaderStatus] = useState(false)
    useEffect(()=>{
        setLoaderStatus(loading)
    },[loading])
    return ( <div className="p-5 confirm-card rounded">
        <div className="flex justify-end">
                
                <div onClick={()=>dispatch(alert({
                        isOpen : false,
                        component : <></>
                    }))} className="flex justify-end cursor-pointer py-1">
                        <AiOutlineClose />
                </div>
            </div>
        <h2 className="py-2">{message}</h2>
        <div className="flex justify-center">
            <button className="flex justify-center items-center button-error" onClick={onClick}  disabled={loading}>
                            {loaderStatus ?<div className="loader"></div>:label}
            </button>
        </div>
    </div> );
}

export default ConfirmDeleted;