const initialValues = {
        isOpen : false,
        message : "",
        bg_color : ""
}

export const reducerNotification = (state = initialValues , action)=>{
    switch(action.type){
        case "OPEN-OR-CLOSE-NOTIFICATION":
            return{
                ...state,
                isOpen : action.isOpen,
                message : action.message,
                bg_color : action.bg_color
            }

        break;
        default :
            return { ...state }
            break;
    }
}