import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import InputSearch from "../../components/layouts/inputWithIcon";
import CustomCaptcha from "../../components/layouts/captcha";
// for redux 
import { useSelector, useDispatch } from 'react-redux';
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useState } from "react";
import { userInfo } from "../../redux/actions/actionUser";
import {  getCartItems, sendEmailVerify } from "../../functionality/functions";
import { notification } from "../../redux/actions/notification";
import { alert } from "../../redux/actions/alert";
import VerificationCode from "./verification";
import SignUp from "./signup";
import RetypeEmail from "./re-enterEmail";
import { cart } from "../../redux/actions/cart";

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

function SignIn() {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors },watch } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    const [loading, setLoading] = useState(false)
    const [isButtonClick, setIsButtonClick] = useState(false)
    const [isRecaptcha,setIsRecaptcha] = useState(false)
    const onSubmit = async (data) => {
        setIsButtonClick(true)
    
        setLoading(true)
        const {response , message,  statusCode} = await Helper({
            url:apiRoutes.auth.signIn,
            method:'POST',
            body:{
                email: data.email,
                password: data.password
            },
            hasToken: false,
        })
        if(response){
            setLoading(false)
            if(response.data.is_active == 0 || response.data.is_verified == 0){
                localStorage.setItem("verification",JSON.stringify({
                    email : data.email,
                    code : response.data.verification_code,
                    id : response.data.id,
                    password: data.password
                }))
                localStorage.setItem("token",response.data.token)
                const { res, msg} = await sendEmailVerify(data.email,response.data.verification_code)
                if(response) {
                    dispatch(alert({
                        isOpen : true,
                        component : <VerificationCode/>
                    }))
                }else{
                    dispatch(notification({
                        isOpen : true,
                        bg_color : "bg-error" ,
                        message : `There are error when send code to your email.${msg}`
                    }))
                }
            }else{
                window.location.reload()
                
                dispatch(userInfo({
                    email: response.data.email,
                    username: response.data.username,
                    photo: "",
                    mobile : response.data.Mobile,
                    fullName : response.data.full_name,
                    id: response.data.id
                }))
                localStorage.setItem("user",JSON.stringify({
                    email: response.data.email,
                    username: response.data.username,
                    photo: "",
                    mobile : response.data.Mobile,
                    fullName : response.data.full_name,
                    id: response.data.id,
                    code : response.data.verification_code,
                }))
                
            }
            localStorage.setItem("token",response.data.token)   
            
        }else{
            dispatch(notification({
                isOpen : true,
                message :message,
                bg_color : "bg-error"
            }))
            setLoading(false)
        }
        
        
    };
  
    return ( <div className="flex justify-center">

               <form onSubmit={handleSubmit(onSubmit)} className="shadow card-auth rounded-lg p-5 md:p-8">
                    <div onClick={()=>dispatch(alert({
                            isOpen : false,
                            component : <></>
                        }))} className="flex justify-end cursor-pointer py-1">
                            <AiOutlineClose />
                    </div>
                    <h2 className="pb-5">Sign In </h2>
                    <div>
                        <InputSearch register={register("email")} icon={<MdOutlineMail/>} placeholder={"Email"} isDark={true}  />
                        {errors.email && <p className="p-0.5 text-error">{errors.email.message}</p>}
                    </div>
                    <div className="py-5">
                        <InputSearch isPassword={true} type="password" register={register("password")} icon={<IoLockClosedOutline/>} placeholder={"Password"} isDark={true}  />
                        {errors.password && <p className="p-0.5 text-error">{errors.password.message}</p>}
                    </div>
                    <div className="text-center">
                        <span onClick={()=>{
                                dispatch(alert({
                                    isOpen : true,
                                    component : <RetypeEmail/>
                                }))
                            }} className="link-auth">Forget Password ?</span>
                    </div>
                    <div>
                        <CustomCaptcha isButtonClick={isButtonClick} onVerify={(res)=>{
                        
                        if(res && watch("email") != "" && watch("password") !=""){
                            document.getElementById("submit").click()
                        }
                        setIsRecaptcha(res)
                        }}/>
                    </div>
                    <h5 className="py-3">Create an account <span onClick={()=>{
                        dispatch(alert({
                            isOpen : true,
                            component : <SignUp/>
                        }))
                    }} className="link-auth">Sign Up</span> </h5>
                    <div className="flex justify-center">
                        
                        <button id="submit" className="flex justify-center items-center" disabled={loading}>
                            {loading ?<div className="loader"></div>:"Sign In"}
                        </button>
                    </div>
                </form>

    </div> );
}

export default SignIn;