import { useEffect, useState } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes, imgPage, imgSrcCat } from "../../functionality/apiRoutes";
import { useParams } from "react-router-dom";
function DynamicPages() {
    const [ page ,setPage] = useState({})
    const { id } = useParams()
    const [ loading ,setLoading] = useState(true)
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getPage(signal)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        return ()=> controller.abort()
    },[id])
    const getPage = async(signal)=>{

        const {response , message } = await Helper({
            url : apiRoutes.dynamicPage.getOne(id),
            method : "GET",
            signal
        })
        if(response){
            setPage(response.data)
            setLoading(false)
            
        }

    }
    return ( loading?<div className='min-h-96 dynamic-pages w-full flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

        </div>:<div className="grid grid-cols-1 md:grid-cols-2 " >
        <div className="p-5">
            <img loading="lazy" className="w-full h-full rounded object-cover" alt="contact-us" src={page.image ? imgPage+page.image:""} />
        </div>
        <div dangerouslySetInnerHTML={{
            __html: page.content?page.content:"",
        }} className="p-5 flex flex-col justify-center ">
        
        </div>

    </div> );
}

export default DynamicPages;