import { useEffect, useState ,useRef} from 'react';
import { Helper } from '../../functionality/helper';
import { apiRoutes } from '../../functionality/apiRoutes';
import { useDispatch } from 'react-redux';
import AddNewItems from './addNewItems';
import { alert } from '../../redux/actions/alert';
import ConfirmDeleted from '../../components/layouts/confirm';
import { notification } from '../../redux/actions/notification';
import { useNavigate } from 'react-router-dom';

function GroupInfoCard({id,returnedValue}) {
    const [group , setGroup] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        getGroupDetails(signal)
        return ()=> controller.abort()
      }, []);  
    const getGroupDetails = async(signal)=>{
        const { response , message} = await Helper({
          url :  apiRoutes.group.getOne(id),
          hasToken : true,
          method : "GET",
          signal
        })
        if(response){
          setGroup(response.data.group)
  
        }
      }
    const deleteGroup = ()=>{
      dispatch(alert({
        isOpen : true,
        component : <ConfirmDeleted onClick={removeItem} loading={false} message={`Are you sure delete ( ${group.name} ).`} label={"Confirm"} />
      }))
    }
    const removeItem = async (id) =>{
      dispatch(alert({
        isOpen : true,
        component : <ConfirmDeleted  loading={true} message={"Are you sure delete this Group."} label={"Confirm"} />
      }))
      const { response , message } = await Helper({
        url : apiRoutes.group.delete(group.id),
        hasToken : true,
        method : "DELETE"
      })
      if(response){
        navigate("/requests")
        dispatch(notification({
          isOpen : true,
          bg_color : "bg-success",
          message : message
      }))
        dispatch(alert({
          isOpen : false,
          component : <></>
        }))
      }else{
        dispatch(notification({
          isOpen : true,
          bg_color : "bg-error",
          message : message
      }))
        dispatch(alert({
          isOpen : false,
          component : <></>
        }))
      }
    }
    return ( <div className="p-5 group-info">
      
      {"name" in group && <h2 className="pb-2"> {group.name? group.name : "-"} </h2>}
      {"description" in group && <p dangerouslySetInnerHTML={{__html : group.description? group.description : "-"}}></p>}
      <div>
        <div className='flex gap-1 justify-center lg:justify-end items-center'>
            <button disabled={group.deletable == 0} className='button-error' onClick={deleteGroup}>Delete</button>
            <button onClick={()=>{
              dispatch(alert({
                isOpen : true,
                component : <AddNewItems updateGroups={(res)=>returnedValue(res)} id={group.id} />
              }))
            }}>Add Item</button>
          </div>
      </div>

    </div> );
}

export default GroupInfoCard;