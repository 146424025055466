import DataTable from 'react-data-table-component';
import FilterHeader from '../requests/filterHeader';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { alert } from '../../redux/actions/alert';
// Icons
import { IoStar } from "react-icons/io5";
// import AddNewItems from './addNewItems';
import { Helper } from '../../functionality/helper';
import { apiRoutes, imageHost } from '../../functionality/apiRoutes';
import { format } from 'date-fns';
import CustomPagination from '../../components/layouts/customPagination';
import Select from 'react-select';
import ConfirmDeleted from "../../components/layouts/confirm";
import { MdOutlineDelete } from "react-icons/md";
import { notification } from "../../redux/actions/notification";
import Budge from '../../components/layouts/budge';
import OrderInfoCard from './detailsOrderInfo';

function ItemsOrder() {
      const [searchParams,setSearchParams] = useSearchParams();
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const { id } = useParams()
      const [data ,setData ] = useState([]) 
      const page = useRef(1)
      const totalPage = useRef(0)
      const size = useRef(5)
      const name = useRef("")
      const directionRef = useRef("")
      const columnRef = useRef("")
      const [loading , setLoading] = useState(true)

      const controllerRef = useRef()
      // Columns configuration
      const columns = [
        {
          name: 'Action',
          selector: row => <div className='flex gap-2'>
            <div className="icon-success  cursor-pointer " onClick={()=>{navigate(`/item/${row.id}`)}} ><GrView/></div>
            
          </div>,
          sortable: false, // No sorting for this column
        },
        {
            id: "id",
            name: 'ID',
            selector: row => row.id,
            sortable: true, // Allows sorting by this column
          },
          {
            name: 'Photo',
            selector: row => <img className="rounded h-10 w-10 object-contain" alt={row.name} src={row.img} />,
            sortable: true,
          },
          {
            id: "name",
            name: 'Name ',
            selector: row => row.name,
            sortable: true,
          },
          {
              id: "brand_id",
              name: 'Brand',
              selector: row => row.brand,
              sortable: true, // No sorting for this column
            },
          {
              id: "category_id",
              name: 'Category',
              selector: row => row.category,
              sortable: true,
            },
           
            {
              id: "amount",
              name: 'Amount',
              selector: row => row.amount,
              sortable: true, // No sorting for this column
            },
            {
              id: "price",
              name: 'Price ',
              selector: row => row.price,
              sortable: true,
            },
            {
              id: "total",
              name: 'Total ',
              selector: row => row.total,
              sortable: true, // No sorting for this column
            },
            {
              id: "coin",
              name: 'Currency ',
              selector: row => row.coin,
              sortable: false, // No sorting for this column
            },
            {
              id : "visitors",
              name: 'Visitors',
              selector: row => row.visitors,
              sortable: true, // No sorting for this column
            },
            {
              id : "star",
              name: 'Star',
              selector: row => <div><IoStar className={`${row.rate?"text-warning":"text-zinc-700"}`} /></div> ,
              sortable: true, // No sorting for this column
            },
            {
              id : "created_at",
              name: 'Date',
              selector: row => row.date,
              sortable: true, // No sorting for this column
            },
            {
              id : "is_new",
              name: 'New',
              selector: row => <Budge type="item"  status={row.is_new} />,
              sortable: false, // No sorting for this column
            },
         
      ];
      const resetFilter  =  ()=>{
        setSearchParams(new URLSearchParams()); // Clear all parameters
      }
      // Custom Styles
      const customStyles = {
        headCells: {
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#f3f4f6',
            color: '#4b5563',
          },
        },
        rows: {
          style: {
            fontSize: '14px',
          },
        },
      };

      const handleSort = (column, direction) => {
        if(column.id){
          columnRef.current = column.id
          directionRef.current = direction;
          searchParams.set("direction",directionRef.current)  
          searchParams.set("column",columnRef.current) 
          setSearchParams(searchParams)
        }
      };
      const getOrder= async (pageParameter = 1 , sizeParameter = 5 , searchName = "")=>{
       setData([])
       if(controllerRef.current){
          controllerRef.current.abort()
       }
       let params = {
        per_page : sizeParameter,
        page_number : pageParameter ,
       }
       if(name.current.length > 2)
        params["name"] = name.current
       if(directionRef.current ) 
        params["sorting_type"] = directionRef.current
       if(columnRef.current)
        params["sortable_column"] = columnRef.current

       controllerRef.current = new AbortController()
       const signal = controllerRef.current.signal
        const {response ,message } = await Helper({
          url : apiRoutes.order.getOne(id),
          method : "GET",
          hasToken : true,
          signal,
          params
        })
        if(response){
          setLoading(false)
          const newData = response.data.map((element) => ({ id: element.id,
            img: element.image ? imageHost + element.image : "", 
            name:  element.name ? element.name : "-", 
            price: element.price && element.commission ?  (parseInt(element.price) + parseFloat(element.commission)) : "-",
            brand : element.brand_name ?  element.brand_name : "-",
            category : element.category_name ? element.category_name : "-", 
            total: element.pivot.price && element.commission && element.pivot.amount ? parseFloat(element.pivot.amount)*(parseFloat(element.pivot.price)+ parseFloat(element.commission)) : "-" , 
            amount : element.pivot.amount ? element.pivot.amount : "-",
            date : element.created_at ? format(element.created_at, 'dd/MM/yyyy') : "-",
            is_new : element.is_new != null ? element.is_new : "-",
            rate : element.star != null ? element.star : "-",
            coin : element.coin.symbol != null ? element.coin.symbol : "-",
            visitors : element.visitors != null ? element.visitors : "-",
            }));
      
          setData((prev) => [...prev, ...newData]);
          totalPage.current = response.meta.total
          
        }else{
          console.log(message);
          
        }
      }
      useEffect(() => {
        if(!localStorage.getItem("user"))
          navigate("/")
        getOrderFun()
      }, [searchParams]);  
      const getOrderFun = ()=>{
        name.current = searchParams.get("name") ? searchParams.get("name") : ""
        directionRef.current = searchParams.get("direction") ? searchParams.get("direction") : ""
        columnRef.current = searchParams.get("column") ? searchParams.get("column") : ""
        page.current = searchParams.get("page") ? searchParams.get("page") : 1
        size.current = searchParams.get("size") ? searchParams.get("size") : 5
        searchParams.set("page",page.current) 
        searchParams.set("size",size.current) 

        getOrder(page.current,  size.current); // Fetch data based on URL params
      }
      const sizeArray = [{label : 5,value:5},{label : 10,value:10},{label : 50,value:50}]
     

      

    return ( loading?<div className='min-h-96 flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

    </div>:<div style={{ padding: '10px' }}>

        <OrderInfoCard id={id} />
        <FilterHeader type="group" inputSearch={name.current} reset={resetFilter} returnedValueSearch={(res)=>{
          if(res.length> 2 ){
            searchParams.set("page",1)  
            searchParams.set("name",res)  
            setSearchParams(searchParams)
          }else if( res.length == 0){
            searchParams.delete("name") 
            searchParams.set("page",1)   
            setSearchParams(searchParams) 
          }
        }} />
        <DataTable 
          highlightOnHover // Highlights rows on hover
          customStyles={customStyles} // Applies custom styles
          columns={columns}
          data={data}
           // Page size options
        />
        <div className='flex items-center justify-center mt-5 lg:justify-end gap-2'>
            <div className='flex items-center justify-center'>
              <Select options={sizeArray}
                onChange={(option)=>{
                  searchParams.set("page",1)  
                  searchParams.set("size",option.value)  
                  setSearchParams(searchParams)
                  
                }}
                value={sizeArray.find(option => option.value == size.current)}
              />
            </div>
            <CustomPagination
              currentPage={parseInt(page.current)}
              rowsPerPage={parseInt(size.current)}
              rowCount={parseInt(totalPage.current)}
              onSort={handleSort}
              onChangePage={(newPage) => {
                 searchParams.set("page",newPage)  
                 setSearchParams(searchParams)
            }}
            />
            
        </div>
        
      </div>);
}

export default ItemsOrder;