
import Input from "../layouts/inputWithIcon";
import img from "../../images/back.png"
import { Link, useNavigate } from "react-router-dom";

// Icons 
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

function Footer() {
    const navigate = useNavigate("/")
    return ( <div className="footer px-5 md:px-24 py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
            <div className="">
                <h2>We Market</h2>
                <p>
                Discover We Market, your all-in-one e-commerce platform designed to simplify online shopping and boost your sales.
                From intuitive storefronts to secure payments and fast delivery, we empower your business with tools for success. Shop, sell, and grow with We Market today!
                </p>
            </div>
                
                
            <div className="menu flex flex-col md:justify-center  md:items-center ">
                    <h2>Information</h2>
                    <ul>
                        <li>
                            <Link to={"/page/8"}>
                                About Us
                            </Link>
                           
                        </li>
                        <li>
                            
                            <Link to={"/page/11"}>
                            Services
                            </Link>
                        </li>
                        <li >
                            
                            <Link to={"/page/12"}>
                            Contact Us
                            </Link>
                        </li>
                        <li >
                            <Link to={"/page/7"}>
                            Terms Of Use
                            </Link>
                            
                        </li>
                        <li >
                            <Link to={"/page/9"}>
                            Private Policy
                            </Link>
                            
                        </li>
                    </ul>
                </div>
                <div className="">
                
            
                <div className="flex justify-center cursor-pointer" onClick={()=>{
                    navigate("/")
                    window.scrollTo({ top: 0,  behavior: 'smooth' });
                }}>
                    <div className="flex justify-center items-center">
                        <img className="w-32" alt="logo" src={img} />
                    </div>
                </div>
                
        
            {/* <p>Discover We Market, your all-in-one e-commerce platform designed to simplify online shopping and boost your sales. From intuitive storefronts to secure payments and fast delivery, we empower your business with tools for success. Shop, sell, and grow with We Market today!</p> */}
       
            <div className="flex justify-center  ">
                <ul className="flex gap-2">
                    <li>
                        <a href="mailto:info@wemarketglobal.com">
                            <div className="footer-icon">
                                <MdOutlineMail/>
                            </div>
                        </a> 
                    </li>
                    <li>
                        <a href="tel:+2349058888882">
                            <div className="footer-icon">
                                <IoMdCall/>
                            </div>
                        
                        </a>
                    </li>
                    <li>

                        <a href="https://wa.me/2349058888882" target="_blank" >
                            <div className="footer-icon">
                                <FaWhatsapp/>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            {/* <div className="flex gap-2">
                <div>
                    <Input icon={<MdOutlineMail/>} placeholder={"Email"} isDark={true}  />
                </div>
                <button>Subscribe</button>
            </div> */}
        </div>
        </div>
    </div> );
}

export default Footer;



