import { MdPhoneCallback } from "react-icons/md";
import { LiaShippingFastSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";

function Services() {
    return ( <div className="services  mt-5 md:mt-10">
        <div className="flex justify-center pt-10 pb-5">
           <h2> Our Services</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="px-5 md:px-10">
                <div className="flex justify-center services-icon"><MdPhoneCallback/></div>
                <h5 className="flex justify-center py-4">24/7 Customer Services</h5>
                <p className="flex justify-center  text-center">Our dedicated support team is available around the clock to assist you with any inquiries, ensuring a seamless shopping experience.</p>

            </div>
            <div className="px-5 md:px-10">
                <div className="flex justify-center services-icon"><LiaShippingFastSolid/></div>
                <h5 className="flex justify-center py-4">Shipping Worldwide</h5>
                <p className="flex justify-center text-center">Enjoy fast and reliable shipping to every corner of the globe. We ensure your products arrive on time and in perfect condition, no matter where you are.</p>

            </div>
            <div className="px-5 md:px-10">
                <div className="flex justify-center services-icon"><GiTakeMyMoney/></div>
                <h5 className="flex justify-center py-4">Payment Comfortable</h5>
                <p className="flex justify-center  text-center">Choose from a variety of secure payment methods that make your shopping experience smooth and hassle-free. Whether it's credit cards, PayPal, or other payment gateways, we've got you covered.</p>


            </div>

        </div>
    </div> );
}

export default Services;