import video from "../../images/mobile.gif"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Navigation,EffectFade, Pagination,Autoplay, Mousewheel, Keyboard } from 'swiper/modules';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { alert } from "../../redux/actions/alert"
import SignIn from "../../pages/auth/signIn"

function Intro() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const information = [{
        hint:"Complete your joy. Register today!",
        des:"Discover a hassle-free shopping experience. Buy what you want, the easy way!",
        image:"",
        buttonTitle:"Sign In",
        action:()=>{
            dispatch(alert({
                isOpen :true,
                component : <SignIn/>
            }))
        }
    },{
        hint:"Shop Anywhere !",
        des:"Shop from any location, and we’ll deliver your orders quickly and conveniently.",
        image:"",
        buttonTitle:"Start",
        action:()=>{
            navigate("/items")
        }
    },{
        hint:"Offers You'll Love!",
        des:"Stay updated on the latest shipping discounts and enjoy top quality service.",
        image:"",
        buttonTitle:"Discover",
        action:()=>{
            navigate("/items")
        }
    }]
    const [image, setImage] = useState(information[0].image)
    const [count, setCount]= useState(0)

    // Image compression function (optimized for GIFs)
    
    
    
    useEffect(()=>{
        const changePhoto =  setInterval(()=>{
            if(count >= 2){
                setCount(0)
                setImage(information[0].image)
            }else{
                setImage(information[count+1].image)
                setCount((prev)=>{
                    const temp = prev
                    return temp+1
                })
            }
           
        },2500)
        return()=>{
            clearInterval(changePhoto)
        }
    },[image])

    
    return ( <div className="">
        
            <div  className="md:h-96 flex justify-between md:flex-row flex-col-reverse px-5 md:px-10  lg:px-24 items-center">

                <div className="w-full md:w-1/2  " >
                <Swiper
                        cssMode={true}
                        navigation={false}
                        autoplay={{ delay: 2500, disableOnInteraction: false }}
                        pagination={false}
                        mousewheel={true}
                        loop={true}
                        slidesPerView={1}
                        modules={[Navigation,Autoplay, Pagination]}
                        className="mySwiper "
                    
                        >
                        
                        {
                            information.map((item,index)=>(<SwiperSlide key={item.buttonTitle+index}>
                                <div className="w-full ">
                                    <span className="hidden  md:block">{item.hint}</span>
                                    <h1 className=" my-4 text-center md:text-start md:block">{item.des}</h1>
                                    <div className="flex justify-center md:block">
                                        <button onClick={item.action}>
                                            {item.buttonTitle}
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>))
                        }
                        </Swiper>
                  
                    
                </div>
                <div className="h-full flex justify-end md:w-1/2">
                        <div className=" h-full flex justify-end">
                                 <img className="h-full object-containt img-hero" alt="easy marketing" src={video}  />
                                 {/* <video   autoPlay muted loop   src={video} /> */}
                        </div>
                    
                </div>


                </div>
    
    
    </div>);
}

export default Intro;