import Select from "react-select";
import InputSearch from "../../components/layouts/inputWithIcon";
import { useEffect, useState } from "react";

function FilterHeader({isSearchName = true,returnedValueSearch,returnedIdValue,reset,inputSearch,id,type}) {
    const [ Input , setInput ] = useState("")
    const [ select, setSelect] = useState("") 
    const status = type != "item" ?[{label : "New",value : "0"},
                                     {label : "Reused",value : "1"}]
                    :[{label : "Accepted",value : "1"},
                    {label : "Pending",value : "0"},
                    {label : "Rejected",value : "2"}]

    useEffect(()=>{
        if(id){
            setSelect(status.find(e=> e.value == id))
        }else{
            setSelect("")
        }
        
    },[id])
    useEffect(()=>{setInput(inputSearch)},[inputSearch])
    
    return ( <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 bg-white p-5">
        <div className="flex gap-1">
            {isSearchName && <div>
                <InputSearch value={Input} onChange={(e)=>{
                    setInput(e.target.value)
                    returnedValueSearch(e.target.value)}} isDark={true} placeholder={"Name"} />
            </div>}
            {type == "item" && <div>
                <Select 
                    options={status}
                    value={select}
                    onChange={(option) => {
                        setSelect(option)
                        returnedIdValue(option.value)}}
                    placeholder="Status"
                    />
            </div>}
            
        </div>
        <div className="flex justify-start lg:justify-end  gap-1">

            <div>
                <button onClick={()=>{
                    reset()
                    setInput("")
                    setSelect("")
                }}> Reset Filter </button>
            </div>
        </div>
        
    </div> );
}

export default FilterHeader;