

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import Card from "../layouts/card";
import { useEffect, useState } from 'react';
import { Helper } from '../../functionality/helper';
import { apiRoutes, imageHost } from '../../functionality/apiRoutes';
import { currency } from '../../functionality/data';
import { useNavigate } from 'react-router-dom';


function LatestItems() {
    const [ items , setItems ] = useState([])
    const [loading, setLoading ] = useState(true)
    const navigate = useNavigate()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        
        const getItems = async (signal)=>{
            const {response, message } = await Helper({
                url : apiRoutes.home.latestItems,
                method : "GET",
                signal
            })
            if(response){
                setLoading(false)
                setItems(response.data)
            }
        }

        getItems(signal)
        return ()=> controller.abort()
    },[])


    return ( <div className='mt-3  min-h-64'>
        <div className="flex justify-center">
            <h2>Latest Items</h2>
        </div>
        {loading?<div className='min-h-96 dynamic-pages w-full flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

        </div>:<div>
                <Swiper
                cssMode={true}
                navigation={true}
                pagination={false}
                mousewheel={true}
                keyboard={true}
                slidesPerView={5}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper "
                breakpoints={{
                    // When the screen width is >= 640px (mobile screens)
                    200: {
                        slidesPerView: 2, // Show 1 slide per view
                    },
                    // When the screen width is >= 640px (mobile screens)
                    500: {
                        slidesPerView: 3, // Show 1 slide per view
                    },
                    // When the screen width is >= 768px (tablet screens)
                    768: {
                        slidesPerView: 4, // Show 2 slides per view
                    },
                    // When the screen width is >= 1024px (medium to large screens)
                    1024: {
                        slidesPerView: 4, // Show 3 slides per view
                    },
                    // When the screen width is >= 1280px (large screens)
                    1280: {
                        slidesPerView: 5, // Show 4 slides per view
                    },
                }}
                >
                    {items.length>0 && items.map((e,index)=>{
                        return <SwiperSlide  key={e.name+e.price+index} >
                            <div className="flex justify-center p-3 lg:p-5 ">
                                <Card visitor={e.visitors ? e.visitors : 0 } category={e.category_name?e.category_name:""} brand={e.brand_name?e.brand_name:""} quantity={e.amount != null ? e.amount : "-"} star={e.star != null ? e.star : 0}  id={e.id} image={e.image?`${imageHost + e.image}`:""}
                                    status={e.is_new != null ? e.is_new : ""} className={e.is_new == "0" ?"bg-success":"bg-error"} title={e.name ? e.name : ""} price={e.price != null && e.commission != null ? (parseFloat(e.price) + parseFloat(e.commission)) +" " + e.coin?.symbol :"-"}
                                    oldPrice={e.oldPrice?e.oldPrice:""}/>
                            </div>
                        </SwiperSlide>
                    })}
                 <SwiperSlide >
                        <div className="flex justify-center p-3 lg:p-5 ">
                          
                          <div className="shadow  p-3 flex justify-center items-center card-product-img">
                             <button onClick={()=>{ navigate(`/items?page=1&size=12`)}}>See More</button></div>
                        </div>
                                
                    </SwiperSlide>
            </Swiper>
        </div>}
       
        
        
    </div> );
}

export default LatestItems;