import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helper } from "../../functionality/helper";
import { apiRoutes, imageHost } from "../../functionality/apiRoutes";
import InputSearch from "../../components/layouts/inputWithIcon";
import Budge from "../../components/layouts/budge";
import { MdModeEdit } from "react-icons/md";
import { VscArrowSmallLeft } from "react-icons/vsc";
import Select from "react-select";
import { getBrands, getCategory } from "../../functionality/functions";
import imageCompression from 'browser-image-compression';
import { format } from 'date-fns';
import { IoStar } from "react-icons/io5";
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { notification } from "../../redux/actions/notification";
import { useDispatch } from "react-redux";
import { MdOutlineDelete } from "react-icons/md";
import ConfirmDeleted from "../../components/layouts/confirm";
import { alert } from "../../redux/actions/alert";

const validationSchema = Yup.object().shape({
    name: Yup.string().min(4, 'Full Name must be at least 4 characters').required('Full Name is required'),
    description: Yup.string().nullable() ,
    price: Yup.number().typeError('Price must be a number type')
        .min(0, 'Price cannot be negative') // Ensure the number is zero or greater
        .required('Price is required'),
    amount: Yup.number().typeError('Price must be a number type')
        .min(0, 'Amount cannot be negative') // Ensure the number is zero or greater
        .required('Amount is required'),
    Minimum_sale: Yup.number()
        .typeError('Price must be a number')
        .min(0, 'Minimum sale cannot be negative')
        .required('Minimum sale is required')
        .test(
          'is-less-than-amount',
          ({ value }) => `The minimum sale value cannot exceed ${parseFloat(value)-1}.`, // Custom error message
          function (value) {
            const { amount } = this.parent; // Access the value of the "amount" field
            return value < amount; // Validation logic
          }
        ),
  });
//   price must be a `number` type
function ViewAndEditItem() {
    const { id } = useParams()
    const { group_id } = useParams()
    const { name } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ item , setItem ] = useState({})
    const [ type , setType ] = useState({})
    const [ loading , setLoading ] = useState(true)
    const [ loadingSubmitting , setLoadingSubmitting ] = useState(false)
    const [ brands, setBrands] = useState([])
    const [ categories, setCategories] = useState([])
    const [ message, setMessage] = useState("")
    const status = [{
        label : "New",
        value : 0
    },{
        label : "Reused",
        value : 1
    }]
    const { register, handleSubmit, formState: { errors },setValue,watch } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if(!localStorage.getItem("user"))
            navigate("/")
        getItemDetails(signal)
        getAllCategories(signal)
        getAllBrands(signal)

        return () => controller.abort()
    },[])
    const getItemDetails = async (signal) =>{
        const { response , message } = await Helper({
            url : apiRoutes.item.getOneWithoutHome(id,group_id),
            method : "GET",
            hasToken : true,
            signal
        })
        if(response){
            setLoading(false)
            setValue("name", response.data.name);
            setValue("description", response.data.description);
            setValue("price", response.data.price != null && response.data.commission != null ? (parseFloat(response.data.price) + parseFloat(response.data.commission)):"-");
            setValue("amount", response.data.amount)
            setValue("Minimum_sale", response.data.Minimum_sale)
            setItem(response.data)
        }
    }
    const getAllCategories = async (signal)=>{
        const {res,msg} = await getCategory(signal)
        if(res){
            res.data.forEach(element => {
                setCategories(prev=>[...prev,{
                    value : element.id,
                    label : element.name
                }])
            });
           
        }

    }
    const getAllBrands = async (signal)=>{
        const {res,msg} = await getBrands(signal)
        if(res){
            res.data.forEach(element => {
                setBrands(prev=>[...prev,{
                    value : element.id,
                    label : element.name
                }])
            });
        }
    }
    const price = watch("price") || 0; // Watch the price field
    const amount = watch("amount") || 0; // Watch the amount field
    const inputRef = useRef()
      // Strip HTML tags to convert to plain text for <textarea>
    const htmlToPlainText = (html) =>{
        if (!html) return ""; // If html is null or undefined, return an empty string
        return html.replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags
    };

    // Calculate total whenever price or amount changes
    useEffect(() => {
        const calculatedTotal = parseFloat(price) * parseFloat(amount);
        setItem(prev=>({...prev,total: isNaN(calculatedTotal) ? 0 : calculatedTotal})); // Handle NaN gracefully
    }, [price, amount]);
    useEffect(()=>{setType(name)},[name])
    const onSubmit = async (data)=>{
        console.log(data);
        // return
        
        setMessage("")
        setLoadingSubmitting(true)
        if("img" in item)
            uploadFile()

        const body = {
            ...(data.name && { name: data.name}),
            ...(data.amount && { amount: data.amount }),
            ...(data.price && { price : data.price }),
            ...(data.description && { description: data.description }),
            ...(item.total !=null && {total : item.total }),
            ...(item.is_new !=null && {is_new : item.is_new }),
            ...(item.brand_id != null && {brand_id : item.brand_id }),
            ...(item.Minimum_sale != null && {minimum_sale : data.Minimum_sale }),
            ...(item.category_id != null && {category_id : item.category_id })
        }; 
        
        const {response , message, statusCode} = await Helper({
            url: apiRoutes.item.update(id),
            method : "PUT",
            hasToken : true,
            body : body
        })
        if(response){
            setLoadingSubmitting(false)
            
            if(name == "edit"){
                navigate(`/requests/${group_id}/${id}/view`)
            }else{
                setType("view")
            }
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
            getItemDetails()
        }else{
            setLoadingSubmitting(false)
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
        }
        
    }
    const uploadFile = async ()=>{
        const formData = new FormData()
        formData.append("image",item.img)
        const {response , message, statusCode} = await Helper({
            url: apiRoutes.item.updateImage(id),
            method : "POST",
            hasToken : true,
            body : formData
        })
        if(response){
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
        }else{
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
        }

    }
    const handleChangeFile = async(event) =>{
        const imageFile = event.target.files[0];
        const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
        }
        try {
        const compressedFile = await imageCompression(imageFile, options);

        setItem(prev=>({...prev, img : compressedFile,
            preview :URL.createObjectURL(event.target.files[0])  , 
        }))
 
        } catch (error) {
        console.log(error);
        }
    }
    const removeItem = async () =>{
        dispatch(alert({
          isOpen : true,
          component : <ConfirmDeleted  loading={true} message={"Are you sure delete this Group."} label={"Confirm"} />
        }))
        const { response , message } = await Helper({
          url : apiRoutes.item.delete(item.id),
          hasToken : true,
          method : "DELETE"
        })
        if(response){
          navigate(`/requests/${group_id}`)
          dispatch(notification({
            isOpen : true,
            bg_color : "bg-success",
            message : message
        }))
          dispatch(alert({
            isOpen : false,
            component : <></>
          }))
        }else{
          dispatch(notification({
            isOpen : true,
            bg_color : "bg-error",
            message : message
        }))
          dispatch(alert({
            isOpen : false,
            component : <></>
          }))
        }
      }
    return ( <div className="flex justify-center">
        <div className="content-item mt-5 p-5 w-full lg:w-3/4">
                {loading?<div className="w-full h-full flex justify-center items-center">
                    <div className="bounce"></div>
                    <div className="bounce"></div>
                    <div className="bounce"></div>
                    </div>: <form onSubmit={handleSubmit(onSubmit)}>
                        {item.status == 0 &&   <div className="relative">
                            <div className="flex justify-end gap-2">
                                <div onClick={()=>{
                                    dispatch(alert({
                                        isOpen : true,
                                        component : <ConfirmDeleted onClick={removeItem} loading={false} message={"Are you sure delete this Item."} label={"Confirm"} />
                                      }))
                                }}  className="edit-icon !bg-red-500">
                                    <MdOutlineDelete/>
                                </div>
                                {
                                    name != "edit"?(type != "edit"? <div className="edit-icon" onClick={()=>{
                                        setType("edit")
                                        getItemDetails()
                                    }}> <MdModeEdit/> </div>
                                    : <div className="edit-icon" onClick={()=>{
                                        setType("view")
                                        getItemDetails()
                                    }}> <VscArrowSmallLeft/> </div>):""
                                }
                            </div>
                            
                        </div>}
                       
                        
                        <div className="flex flex-col-reverse md:flex-row ">
                            <div className="w-full  md:w-1/2 ">
                                <div className="w-1/4 pb-2">
                                    <Budge type="group"  status={item.status} />
                                </div>
                                <p className="flex ">
                                <IoStar className={`${item.star?"text-warning":"text-zinc-700"}`} /> 	&nbsp; Visitors : {item.visitors? item.visitors:"-"} / Date : {item.created_at?format(item.created_at, 'dd/MM/yyyy'):"" }  
                                </p>
                                <h3 className="pb-2">Name :</h3>
                                <div className="pb-2">
                                    <InputSearch disabled={type == "view"?true:false} className="h-10" isDark={true}  register={register("name")} value={watch("name")} placeholder={"Name"} />
                                    {errors.name && <p className="p-0.5 text-error">{errors.name.message}</p>}
                                </div>
                                <h3 className="pb-2">Group :</h3>
                                <div className="pb-2">
                                        <p className="content-view">{item.group_name? item.group_name :""}</p> 
                                </div>
                                
                            
                            </div>
                            <div className="w-full flex flex-col justify-center  items-center  md:w-1/2 ">
                                <div className="image-item">
                                    
                                        <img  alt={item.name? item.name :"item"} src={"preview" in item? item.preview: imageHost + item.image} />
                                </div>
                                <input type="file" ref={inputRef} accept="image/jpeg, image/png, image/gif, image/webp, image/svg+xml" onChange={handleChangeFile} className="hidden" />
                                {item.status == 0 && type == "edit"  &&  <div className="flex edit-photo items-center cursor-pointer h-6 mt-2 p-1"  onClick={()=>{
                                                inputRef.current.click()
                                            }}> <MdModeEdit/> Edit</div>}
                                
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div>
                                    <h3 className="pb-2">Description :</h3>
                                    {type == "view"? <p  dangerouslySetInnerHTML={{__html : item.description? item.description :""}} className="content-view"></p> :
                                    <textarea className="w-full bg-inherit"   {...register("description")} value={htmlToPlainText(watch("description"))} placeholder={"Description"} />
                                    }
                                    {errors.description && <p className="p-0.5 text-error">{errors.description.message}</p>}
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            <div className=" ">
                                <h3 className="pb-2">Price :</h3>
                                <div>
                                    <InputSearch disabled={type == "view"?true:false} type={"number"} isDark={true} register={register("price")} value={watch("price")} placeholder={"Price"} />
                                    {errors.price && <p className="p-0.5 text-error">{errors.price.message}</p>}
                                </div>
                                
                            </div>
                            <div className=" ">
                            <h3 className="pb-2">Total :</h3>
                                <div> 
                                    <InputSearch disabled={true} type={"number"} isDark={true}  value={item.total? item.total :""} placeholder={"Total"} />
                                </div>
                            </div>
                            <div className=" ">
                                
                                <h3 className="pb-2">Amount :</h3>
                                <div><InputSearch disabled={type == "view"?true:false} type={"number"} isDark={true}  register={register("amount")} value={watch("amount")} placeholder={"Amount"} /></div>
                                
                                
                            </div>
                            <div className=" ">
                                
                                <h3 className="pb-2">Minimum Amount :</h3>
                                <div><InputSearch  disabled={type == "view"?true:false} type={"number"} isDark={true}  register={register("Minimum_sale")} value={watch("Minimum_sale")} placeholder={"Minimum Amount "} /></div>
                                {errors.Minimum_sale && <p className="p-0.5 text-error">{errors.Minimum_sale.message}</p>}
                                
                            </div>
                            <div className=" ">
                                <h3 className="pb-2" >Is New :</h3>
                                { type == "view"  ? <div className="content-view">
                                        {item.is_new != null ? ( item.is_new == 0 ? "New" : "Reused" ):""}
                                </div> :<Select value={status.find(option => option.value == item.is_new)} onChange={(option)=>{
                                    setItem(prev =>({...prev,is_new:option.value}))
                                }} options={status} /> }

                            </div>
                            <div className=" ">
                                <h3 className="pb-2">Category :</h3>
                                { type == "view"  ? <div className="content-view">
                                        {item.category_id  ? ( item.category_name  ? item.category_name : "" ):""}
                                </div> :<Select value={categories.find(option => option.value == item.category_id)} onChange={(option)=>{
                                    setItem(prev =>({...prev,category_id:option.value}))
                                }} options={categories} />}

                            </div>
                            <div className=" ">
                                <h3 className="pb-2" >Brand :</h3>
                                { type == "view"  ? <div className="content-view">
                                        {item.brand_id  ? ( item.brand_name  ? item.brand_name : "" ):""}
                                </div> :<Select value={brands.find(option => option.value == item.brand_id)} onChange={(option)=>{
                                    setItem(prev =>({...prev,brand_id:option.value}))
                                }} options={brands} />}

                            </div>
                            </div>
                            


                        </div>
                        {type == "view" && item.status == 2 &&  <div className=" ">
                                <h3 className="pb-2" >Reason Rejection :</h3>
                                {  item.status == 2 ? <p className="content-view">
                                        {item.Reason_rejection  ? ( item.Reason_rejection  ? item.Reason_rejection : "" ):""}
                                </p> :""  }

                        </div>}

                        {
                            type == "edit" && <div className="p-5 flex justify-center">
                            <button  className="flex justify-center items-center"  disabled={loadingSubmitting}>{loadingSubmitting?<div className="loader"></div>:"Save"}</button>
                        </div>
                        }
                       
                        </form>}
        </div>

    </div> );
}

export default ViewAndEditItem ;