// src/redux/store.js
import { createStore } from 'redux';
import { combineReducers } from 'redux';
import { reducerUser } from './reducers/reducerUser';
import { reducerNotification } from './reducers/notification';
import { reducerAlert } from './reducers/alert';
import { reducerLoading } from './reducers/loading';
import { reducerCart } from './reducers/cart';

const rootReducer = combineReducers({
    user: reducerUser,
    notification : reducerNotification,
    alert : reducerAlert,
    loading : reducerLoading,
    cart : reducerCart
    // Add other reducers here
});

const store = createStore(rootReducer);

export default store;