import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
function FilterHeader({categories}) {
    const [ category_id, setCategory_id]= useState("")
    const navigate = useNavigate()
    const [searchParams,setSearchParams] = useSearchParams();
    useEffect(()=>{
        setCategory_id( searchParams.get("category_id") ? searchParams.get("category_id") : "")
    },[searchParams])
    const clickCategory = (id)=>{
        setCategory_id(id)
        searchParams.set("category_id",id) 
        setSearchParams(searchParams) 
    }
    const containerRef = useRef(null);

    const scrollByAmount = (amount) => {
        containerRef.current.scrollBy({ left: amount, behavior: "smooth" });
    };
    return ( <div className="w-full flex">
        <div className="flex items-center cursor-pointer px-1" onClick={() => scrollByAmount(-100)}> 
            <IoIosArrowBack/>
        </div>
        
        <div ref={containerRef} className="flex gap-3 root-category">
        
            <p className={`${category_id ? "category-div px-3": "active-cat px-3"} flex items-center`} onClick={()=>{
                    navigate(`/items?page=1&size=12`)
                }}>All</p>
            {categories.map((category)=>(<div onClick={()=>clickCategory(category.id)} className={`${category_id == category.id? "active-cat":"category-div "} flex`}  key={`Category_filter_${category.name?category.name:""}`}>
                <p className="flex items-center gap-2">{category.name?category.name:""}{" "} <span className="items-count">{category.items_count != null ? category.items_count :0}</span></p>

            </div>))}
            
        </div>
        <div className="flex items-center cursor-pointer px-1" onClick={() => scrollByAmount(100)}> 
            <IoIosArrowForward/>
        </div>
    </div> );
}

export default FilterHeader;