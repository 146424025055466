import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { alert } from "../../redux/actions/alert";
import ItemForm from "./itemForm";
import { getBrands, getCategory, getCoins } from "../../functionality/functions";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { notification } from "../../redux/actions/notification";

function AddNewItems({id,updateGroups}) {
    const dispatch = useDispatch()
    const [ brands, setBrands] = useState([])
    const [ categories, setCategories] = useState([])
    const [ coins, setCoins] = useState([])
    const [loading , setLoading] = useState(false)
    useEffect(()=>{
        const controller = new AbortController()
        const signal  = controller.signal

        getAllCategories(signal)
        getAllBrands(signal)
        getAllCoins(signal)

        return()=> controller.abort()
    },[])
    const getAllCategories = async (signal)=>{
        const {res,msg} = await getCategory(signal)
        if(res){
            res.data.forEach(element => {
                setCategories(prev=>[...prev,{
                    value : element.id,
                    label : element.name
                }])
            });
           
        }

    }
    const getAllBrands = async (signal)=>{
        const {res,msg} = await getBrands(signal)
        if(res){
            res.data.forEach(element => {
                setBrands(prev=>[...prev,{
                    value : element.id,
                    label : element.name
                }])
            });
        }
    }
    const getAllCoins = async (signal)=>{
        const {res,msg} = await getCoins(signal)
        if(res){
            res.data.forEach(element => {
                setCoins(prev=>[...prev,{
                    value : element.id,
                    label : element.symbol
                }])
            });
        }
    }
    const handleSubmitItem = async (updatedData)=>{
        setLoading(true)
        const formData = new FormData()

        formData.append("group_id",updatedData.group_id)
        formData.append("category_id",updatedData.category_id)
        formData.append("brand_id",updatedData.brand_id)
        formData.append("name",updatedData.name)
        formData.append("is_new",updatedData.status)
        formData.append("price",parseFloat(updatedData.price))
        formData.append("amount",parseInt(updatedData.amount))
        formData.append("total",parseFloat(updatedData.total))
        formData.append("image",updatedData.img)
        formData.append("coin_id",updatedData.coin_id)
        formData.append("minimum_sale",updatedData.minimum_sale)
        if(updatedData.description.length>0)
            formData.append("description",updatedData.description)
        const { response , message } = await Helper({
            url : apiRoutes.item.add,
            method : "POST",
            hasToken : true,
            body : formData
        })
        if(response){
            dispatch(alert({
                isOpen : false,
                component : <></>
            }))
            dispatch(notification({
                message : message,
                isOpen : true,
                bg_color : "bg-success",
            }))
            updateGroups(true) 
            setLoading(false)
        }else{
            setLoading(false)
            dispatch(notification({
                message : message,
                isOpen : true,
                bg_color : "bg-error",
            }))
        }
        
    }
    return ( <div style={{height :`${window.innerHeight - 200}px`,overflowY:"scroll" }} className="div-scroll bg-white p-5">
        <div className="flex justify-end">
                
            <div onClick={()=>dispatch(alert({
                    isOpen : false,
                    component : <></>
                }))} className="flex justify-end cursor-pointer py-1">
                    <AiOutlineClose />
            </div>
        </div>
        <h2 className="pb-3">Add Item</h2>
        <div>
            <ItemForm coins={coins} loading={loading} group_id={id} onSave={(data)=>handleSubmitItem(data)} categories={categories} brands={brands} />
        </div>
    </div> );
}

export default AddNewItems;