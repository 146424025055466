import { Helper } from "./helper";
import { apiRoutes, Host } from "./apiRoutes";
import store from "../redux/store";
import { cart } from "../redux/actions/cart";
// Send Email
export const signInFunction = async (email,password)=>{
    const { response, message } = await Helper({
        url:apiRoutes.auth.signIn,
        method:'POST',
        body:{
            email: email,
            password: password
        },
        hasToken: false,
    })
    return{
        res :response ,
        msg : message
    }
}
export const sendEmailForResetPassword = async (email)=>{
    const {response , message} = await Helper({
        url : apiRoutes.auth.sendEmailForResetPassword,
        method : "POST",
        hasToken : false,
        body : {
            email : email ,
        }
    })
    console.log(response);
    
    return {
        res : response ,
        msg : message
    }
}


export const sendEmailVerify = async (email,code)=>{
    const {response,message} = await Helper({
        url : apiRoutes.auth.sendVerificationCode,
        method : "POST",
        hasToken : true,
        body : {
            email : email ,
            code : code
        }
    })
    return {
       res: response,
        msg : message
    }
}
export const getUserInfoByEmail = async (email) =>{
    const {response,message} = await Helper({
        url : apiRoutes.auth.getInfoByEmail(email),
        method : "GET",
        hasToken : false,

    })
    return {
       res: response,
        msg : message
    }
}

export const getBrands = async(signal) =>{
    const {response,message} = await Helper({
        url : apiRoutes.all.brand,
        method : "GET",
        hasToken : true,
        signal

    })
    return {
       res: response,
        msg : message
    }
}
export const getCategory = async(signal) =>{
    const {response,message} = await Helper({
        url : apiRoutes.all.category,
        method : "GET",
        hasToken : true,
        signal

    })
    return {
       res: response,
        msg : message
    }
}
export const getCoins = async(signal) =>{
    const {response,message} = await Helper({
        url : apiRoutes.coins.all,
        method : "GET",
        hasToken : true,
        signal

    })
    return {
       res: response,
        msg : message
    }
}
export const checkUserLogedIn = async (signal)=>{
    try {
        const response = await fetch(`${Host}/check`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token") || ""}`,
            },
            signal,
        });

        const data = await response.json();
        return { response: data };
    } catch (error) {
        // Handle fetch or network errors
        console.error("Error checking user login:", error);
        return { error };
    }

  }

  export const getCartItems = async(signal) =>{
    const {response,message} = await Helper({
        url : apiRoutes.cart.getCart,
        method : "GET",
        hasToken : true,
        signal

    })
    return {
        res : response,
        msg : message
    }
   
}

