
import Badge from "./badge";
import LazyImage from "./lazyLoadImages"
import { Link } from "react-router-dom";
// Icons
import { IoStar } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { useEffect, useState } from "react";


function Card({id,image,title,price,oldPrice,status,className,quantity,star,visitor,brand,category}) {
    const [compressed, setCompressed] = useState(null);
    const compressImage = async (src, maxWidth = 800, maxHeight = 800, quality = 0.7) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Required for external images
    
        return new Promise((resolve, reject) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
    
            // Calculate new dimensions
            let { width, height } = img;
            if (width > maxWidth || height > maxHeight) {
              if (width > height) {
                height = (height / width) * maxWidth;
                width = maxWidth;
              } else {
                width = (width / height) * maxHeight;
                height = maxHeight;
              }
            }
    
            // Set canvas size and draw the image
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
    
            // Convert canvas to data URL
            canvas.toBlob(
              (blob) => {
                resolve(URL.createObjectURL(blob));
              },
              "image/png", // Preserve GIF format
              quality // Compression quality
            );
          };
    
          img.onerror = (error) => reject(new Error("Failed to load image"));
          img.src = src; // Set image source
        });
      };
    useEffect(() => {
        if (image.startsWith("http") && !image.includes(window.location.origin)) {
            setCompressed(image);
            return;
          }
        const compressGif = async () => {
        try {
            const compressed = await compressImage(image);
            setCompressed(compressed);
        } catch (error) {
            console.error("Failed to compress GIF:", error);
        }
        };

        compressGif();
    }, [image]);
    return ( <div className="shadow  p-3 card-product">
    <Link to={`/item/${id}`}>
        <div>
            <div className="flex justify-center card-img">
                <img loading="lazy"  src={compressed || image} alt={title || "Product image"}
                    className="h-40 object-contain" />
            </div>
            <h5 className="font-medium break-all   items-center pt-2 flex items-center !font-bold ">
                {title.length <= 40 ?title : title.substring(0,30)+"..."}
            </h5>
            <div className="flex  items-center  justify-between">
                <p style={{fontSize:"14px"}}>
                    {brand}/{category}
                </p>
                <div style={{fontSize:"12px"}} className="flex gap-1 items-center">
                    <GrView /> {visitor}
                </div>
            </div>
            <p className="font-bold" style={{fontSize:"13px"}}>
                {price} &nbsp; <span className="old">{oldPrice}</span>
            </p>
            <div className="flex py-1 justify-between">
                <div><IoStar className={`${star == 0?"text-zinc-700":"text-warning"}`} /></div>
                <div className="quantity ">Quantity : {quantity}</div>     
                
            </div>

            
            
            
        
        </div>
    
    </Link> 
    <div className="flex status justify-end">
        <div>
            <Badge content={status} className={className} />
        </div>
    </div>

    </div>);
}

export default Card;