import { useNavigate } from "react-router-dom";
import error_image from "../../images/not.webp"
function NotFound() {
    const navigate = useNavigate()
    return ( <div>
        <div className="flex justify-center py-5">
            <img className="h-40 md:h-56 vibration" alt={"error"} src={error_image}/>
        </div>
        <div >
            <h1 className="flex justify-center">
                404&nbsp;Error
            </h1>
            <p className="flex justify-center">Oops ! not found page</p>
            <div className="flex justify-center py-3">
                <button onClick={()=> navigate("/")}>Go To Home</button>
            </div>
        </div>
        

    </div> );
}

export default NotFound;