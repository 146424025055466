import { useEffect, useState } from "react";
import InputSearch from "../../components/layouts/inputWithIcon";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes"

// icons
import { LiaAddressCardSolid } from "react-icons/lia";
import { HiMiniDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineMail } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";


import Select from 'react-select';
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { notification } from "../../redux/actions/notification";
import { loadingAlert } from "../../redux/actions/loading";
import { alert } from "../../redux/actions/alert";
import { Countries } from "../../functionality/data";
import MobileInput from "../../components/layouts/mobileInput";

const validationSchema = Yup.object({
    full_name: Yup.string().min(4, 'Full Name must be at least 4 characters').required('Full Name is required'),
    username: Yup.string().min(4, 'Username must be at least 4 characters').required('Username is required'),
    // Mobile: Yup.string()
    //     .nullable().test(
    //         'is-valid-mobile',
    //         'Mobile number must be exactly 10 digits and contain only numbers',
    //         (value) =>
    //           !value || // Pass validation if empty
    //           (/^\d{10}$/.test(value)) // Otherwise validate the format
    //       ), // Allows it to be empty
    address: Yup.string()
        .nullable() ,// Allows it to be empty
    birthday : Yup.string(),
    gender :  Yup.string(),
  });

function Profile() {
    const [ user, setUser ] = useState({})
    const [ type , setType ] = useState("view")
    const [ loading , setLoading ] = useState(false)
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },setValue,watch } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if(localStorage.getItem("user")){
            getUserInfo(signal)
        }else{
            dispatch(alert({
                isOpen : false,
                component : <></>
            }))
        }
        return ()=> controller.abort()
    },[])
    
    const getUserInfo = async (signal)=>{
        dispatch(loadingAlert({
            isOpen : true
        }))
        const { response , message , statusCode} = await Helper({
            url : apiRoutes.auth.getInfoById(JSON.parse(localStorage.getItem("user")).id),
            method : "GET",
            hasToken: true,
            signal : signal

        })
        if(response){
            setUser(response.data)
             // Initialize form values
             setValue("full_name", response.data.full_name);
             setValue("username", response.data.username);
             setValue("birthday", response.data.birthdate?response.data.birthdate:"");
             setValue("gender", response.data.gender?response.data.gender:"");
             setValue("Mobile", response.data.mobile?response.data.mobile:"");
             setValue("address", response.data.address?response.data.address:"");
             setGender(response.data.gender != null?response.data.gender:null)
             setSelectedCountry(response.data.nationality?response.data.nationality:"")
             dispatch(loadingAlert({
                isOpen : false
            }))
        }else{
            // dispatch(loadingAlert({
            //     isOpen : false
            // }))
        }
    }
    const onSubmit = async (data)=>{
        setLoading(true)


        const body = {
            ...(data.full_name && { full_name: data.full_name}),
            // ...(data.Mobile && { mobile: data.Mobile }),
            ...(data.address && { address: data.address }),
            ...(data.username && { username: data.username }),
            ...(selectedCountry && {nationality : selectedCountry }),
            ...(gender != null && {gender : gender }),
            ...(data.birthday && {birthdate : data.birthday })
        }; 
        
        const {response , message, statusCode} = await Helper({
            url: apiRoutes.auth.update(JSON.parse(localStorage.getItem("user")).id),
            method : "PUT",
            hasToken : true,
            body : body
        })
        if(response){
            setLoading(false)
            setType("view")
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
        }else{
            setLoading(false)
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
        }
        
    }
    // for select 
    const [selectedCountry, setSelectedCountry] = useState("");
    const [gender, setGender] = useState(0);
    const [countries, setCountries] = useState([]);


    return ("email" in user && <div style={{height :`${window.innerHeight - 100}px`,overflowY:"scroll" }} className="div-scroll shadow card-auth rounded-lg p-5 md:p-8  ">
        <form onSubmit={handleSubmit(onSubmit)}  className="profile w-full">
            <div className={`flex ${type == "edit"?"justify-between":"justify-end"} `}>
                {type == "edit" && <div onClick={()=>setType("view")} className="flex justify-end cursor-pointer py-1">
                        <IoArrowBack/>
                </div>}
                <div onClick={()=>dispatch(alert({
                        isOpen : false,
                        component : <></>
                    }))} className="flex justify-end cursor-pointer py-1">
                        <AiOutlineClose />
                </div>
            </div>
            <div className="flex justify-between ">
                <div className="flex flex-col justify-center">
                    <h2>Profile</h2>
                    {type == "view" &&<div onClick={()=>setType("edit")} className="flex items-center link-auth gap-1">
                        <FiEdit2/><span>Edit</span>
                    </div>}
                    
                </div>
                <div className="h-full w-12 h-12 flex items-center justify-center">
                    <p className="font-bold cursor-pointer w-10 h-10 flex items-center justify-center">
                        {/* {JSON.parse(localStorage.getItem("user")).fullName.substring(0,2).toUpperCase()} */}
                        <BsPersonCircle style={{fontSize:"40px"}} />
                    </p> 
                </div>
            </div>
            <div className="py-5">
                <label>Full Name</label>
                <InputSearch disabled={type == "view"?true:false} register={register("full_name")} value={watch("full_name")} icon={<IoMdPerson/>} isDark={true} placeholder={"Full Name"} />
                {errors.full_name && <p className="p-0.5 text-error">{errors.full_name.message}</p>}
            </div>
            <div className="pb-5">
                <label>Username</label>
                <InputSearch disabled={type == "view"?true:false} register={register("username")} value={watch("username")} icon={<IoMdPerson/>} isDark={true} placeholder={"Username"} />
                {errors.username && <p className="p-0.5 text-error">{errors.username.message}</p>}
            </div>
            <div>
                <label>Email<span className="text-error" style={{fontSize: "0.9em"}}> (you can't change email)</span></label>
                <InputSearch disabled={true} value={user.email}  icon={<MdOutlineMail/>} isDark={true} placeholder={"Email"} />
            </div>
            <div className="py-5">
                <label>Mobile<span className="text-error" style={{fontSize: "0.9em"}}> (you can't change email)</span></label>
                {/* <InputSearch disabled={true} register={register("Mobile")} value={watch("Mobile")}  icon={<HiMiniDevicePhoneMobile/>} type={"number"} isDark={true} placeholder={"Mobile"} />
                {errors.Mobile && <p className="p-0.5 text-error">{errors.Mobile.message}</p>} */}
                <MobileInput isOpenMenu={false} value={user.mobile} country_id={user.country_code_id} />
            </div>
            <div>
                <label>Address</label>
                <InputSearch disabled={type == "view"?true:false} register={register("address")} value={watch("address")}  icon={<LiaAddressCardSolid/>} isDark={true} placeholder={"Address"} />
                {errors.address && <p className="p-0.5 text-error">{errors.address.message}</p>}
            </div>
            <div className="py-5">
                <label htmlFor="country" >Nationality</label>
                {type == "view"?<InputSearch value={selectedCountry?selectedCountry:"-"} disabled={type == "view"?true:false} isDark={true}  />: <Select
                    options={Countries.length > 0 ? Countries : []}
                    value={Countries.find(option => option.value === selectedCountry)}
                    onChange={(option) => setSelectedCountry(option.value)}
                    placeholder="Select a country"
                />}
               
                {type == "edit" && <p className="pt-1">Selected Country: {selectedCountry}</p>}
            </div>
            <div className="pb-5 flex gap-2">
                <div className="w-full md:w-1/2  h-10" >
                    <label htmlFor="country">Birthday</label>
                    <InputSearch type="date" disabled={type == "view"?true:false} register={register("birthday")} value={watch("birthday")}  isDark={true} />
                </div>
                <div className="w-full md:w-1/2 h-10" >
                    <label  htmlFor="country">gender</label>
                    {type == "view"?<InputSearch value={gender == 0 ?"Male":(gender == 1 ?"Female":"-")} disabled={type == "view"?true:false} isDark={true}  />: <Select
                    options={[{label:"Male",value:0},
                        {label: "Female",value:1}
                    ]}
                    value={[{label:"Male",value:0},
                        {label: "Female",value:1}
                    ].find(option => option.value == gender)}
                    onChange={(option) => setGender(option.value)}
                    placeholder="Select a Gender"
                />}
                </div>
                
            </div>
            {type == "edit" && <div className="flex justify-center py-5">
                <button className="flex justify-center items-center"  disabled={loading} >
                    {loading ? <div className="loader"></div>:"Save"}
                </button>
            </div>}
            
        </form>

        
    </div> );
}

export default Profile;