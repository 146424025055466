import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import InputSearch from "../../components/layouts/inputWithIcon";
import { IoMdPerson } from "react-icons/io";
import CustomCaptcha from "../../components/layouts/captcha";
// for redux 
import { useDispatch } from 'react-redux';
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { useState } from "react";
import { userInfo } from "../../redux/actions/actionUser";
import {  getCartItems, sendEmailVerify, signInFunction } from "../../functionality/functions";
import { notification } from "../../redux/actions/notification";
import { alert } from "../../redux/actions/alert";
import VerificationCode from "./verification";
import SignIn from "./signIn";
import { cart } from "../../redux/actions/cart";
import MobileInput from "../../components/layouts/mobileInput";
import { Link } from "react-router-dom";

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    fullName: Yup.string().min(4, 'Full Name must be at least 4 characters').required('Full Name is required'),
    username: Yup.string().min(4, 'Username must be at least 4 characters').required('Username is required'),
    password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
    .required('Password is required'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
    mobile: Yup.string()
    .nullable().min(4,'Mobile number must be more 4 digits '), // Allows it to be empty
  });

function SignUp() {
    const dispatch = useDispatch();
    const { register, handleSubmit,trigger, formState: { errors },watch } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    const [isButtonClick, setIsButtonClick] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState({})
    const [isRecaptcha,setIsRecaptcha] = useState(false)
    const [checkValue, setCheckValue] = useState(false)
    const onSubmit = async (data) => {
        setIsButtonClick(true);
            setLoading(true)
            const {response , statusCode, message} = await Helper({
                url:apiRoutes.auth.signUp,
                method:'POST',
                body:{
                    full_name: data.fullName,
                    username: data.username,
                    email: data.email,
                    password: data.password,
                    mobile :"0"+ data.mobile,
                    country_code_id : selectedCountry.id
                },
                hasToken: false,
            })
            if(response){
                setLoading(false)
                const {res , msg} = await signInFunction(data.email,data.password)
                if(res){
                    localStorage.setItem("verification",JSON.stringify({
                        email : res.data.email,
                        code : res.data.verification_code,
                        id : res.data.id,
                        password: data.password
                    }))
                    localStorage.setItem("token",res.data.token)
                    sendCode(res.data.email,res.data.verification_code)
                }else{
                    dispatch(notification({
                        isOpen : true,
                        message : msg,
                        bg_color : "bg-error"
                    }))
                }

            }else{
                setLoading(false)
                dispatch(notification({
                    isOpen : true,
                    bg_color : "bg-error",
                    message : message
                }))
            }
        
        
    };
    const sendCode = async (email,code) =>{
        
        const {res, msg } = await sendEmailVerify(email,code)
        if(res){
            dispatch(alert({
                isOpen : true,
                component : <VerificationCode/>
            }))
        }else{
            dispatch(notification({
                isOpen : true,
                message : msg,
                bg_color : "bg-error"
            }))
        }
    }

    const onPasswordChange = () => {
        // Trigger validation for confirmPassword when password changes
        trigger('confirmPassword');
  };
    return ( <div  className=" flex justify-center mb-8">
                <form  onSubmit={handleSubmit(onSubmit)} style={{height :`${window.innerHeight - 100}px`,overflowY:"scroll" }} className="div-scroll  shadow card-auth rounded-lg p-5 md:p-8">
                    <div onClick={()=>dispatch(alert({
                            isOpen : false,
                            component : <></>
                        }))} className="flex justify-end cursor-pointer py-1">
                            <AiOutlineClose />
                    </div>
                    <h2 className="pb-5">Sign Up </h2>
                    <div>
                        <InputSearch register={register("fullName")} icon={<IoMdPerson/>} placeholder={"Full Name"} isDark={true}  />
                        {errors.fullName && <p className="p-0.5 text-error">{errors.fullName.message}</p>}
                    </div>
                    <div className="py-5">
                        <InputSearch register={register("username")} icon={<IoMdPerson/>} placeholder={"Username"} isDark={true}  />
                        {errors.username && <p className="p-0.5 text-error">{errors.username.message}</p>}
                    </div>
                    <div>
                        <InputSearch register={register("email")} icon={<MdOutlineMail/>} placeholder={"Email"} isDark={true}  />
                        {errors.email && <p className="p-0.5 text-error">{errors.email.message}</p>}
                    </div>
                    <div className="py-5">
                        <MobileInput register={register("mobile")} returnedCountry={(res)=>setSelectedCountry(res)}  />
                        {errors.mobile && <p className="p-0.5 text-error">{errors.mobile.message}</p>}
                    </div>
                    <div >
                        <InputSearch onChange={onPasswordChange} isPassword={true} type="password" register={register("password")} icon={<IoLockClosedOutline/>} placeholder={"Password"} isDark={true}  />
                        {errors.password && <p className="p-0.5 text-error">{errors.password.message}</p>}
                    </div>
                    <div className="py-5" >
                        <InputSearch isPassword={true} type="password" register={register("confirmPassword")} icon={<IoLockClosedOutline/>} placeholder={"Retype Password"} isDark={true}  />
                        {errors.confirmPassword && <p className="p-0.5 text-error">{errors.confirmPassword.message}</p>}
                    </div>
                    <div className="flex gap-2 ">
                        <input onChange={(e)=>setCheckValue(e.target.checked)} className="check-auth" type="checkbox" />
                        <p >
                        I agree to the <a target="_blank" href={"/page/7"}>
                        <span className="text-blue-500 underline cursor-pointer">Terms & Conditions</span>
                        </a> &nbsp;and &nbsp;  <a target="_blank" href={"/page/9"}>
                        <span className="text-blue-500 underline cursor-pointer">Privacy Policy</span>
                        </a>.
                        </p>
                    </div>
                    <div className="pt-5" >
                    <CustomCaptcha isButtonClick={isButtonClick} onVerify={(res)=>{
                        if(res && watch("fullName") != "" && watch("username") !="" &&  watch("email") != "" && watch("password") !="" && watch("confirmPassword") != "" && watch("mobile") !=""){
                            document.getElementById("submit").click()
                        }
                    }}/>
                    </div>
                    <h5 className="py-3">I have an account <span onClick={()=>{
                                                dispatch(alert({
                                                    isOpen : true,
                                                    component : <SignIn/>
                                                }))
                    }} className="link-auth">Sign In</span> </h5>
                    <div className="flex justify-center">
                    
                        <button id="submit" type="submit" className="flex justify-center items-center" disabled={loading || !checkValue}>
                            {loading ?<div className="loader"></div>:"Sign Up"}
                            </button>
                    </div>

                </form>
	
    </div> );
}

export default SignUp;