import img from "../../images/contact.webp"
import Input from "../layouts/inputWithIcon";
// icons
import { IoMdPerson } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";
// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from "react";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";
import { notification } from "../../redux/actions/notification";
import { useDispatch } from "react-redux";
import CustomCaptcha from "../layouts/captcha";
const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    fullName: Yup.string().min(4, 'Full Name must be at least 4 characters').required('Full Name is required'),
    message: Yup.string().min(4, 'Messsage must be at least 4 characters').required('Message is required'),
  });
function ContactUs() {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },reset } = useForm({
        resolver: yupResolver(validationSchema),
         mode: 'onChange'
      });
    const [loading, setLoading] = useState(false)
    const [isRecaptcha,setIsRecaptcha] = useState(false)
    const [isButtonClick, setIsButtonClick] = useState(false)
    const [appendReptcha, setAppendReptcha] = useState(false)
    const onSubmit = async (data) => {
        if(!appendReptcha){
            setAppendReptcha(true)
        }else{
            setIsButtonClick(true)
            if(isRecaptcha){
            setLoading(true)
            const {response , statusCode, message} = await Helper({
                url:apiRoutes.home.contactUs,
                method:'POST',
                body:{
                    full_name: data.fullName,
                    email: data.email,
                    message: data.message
                },
            })
            if(response){
                setLoading(false)
                dispatch(notification({
                    isOpen : true,
                    message : message,
                    bg_color : "bg-success"
                }))
                reset({
                    message: "", 
                    email: "", 
                    fullName: "", 
                });
                setAppendReptcha(false)

            }else{
                setLoading(false)
                dispatch(notification({
                    isOpen : true,
                    bg_color : "bg-error",
                    message : message
                }))
            }
        }else{
            dispatch(notification({
                isOpen : true,
                message : "Complete RECAPTCHA to be ensure you are human",
                bg_color : "bg-error"
            }))
        }
        }
        
    };
    return ( <div className="flex contact md:flex-row flex-col">
        <div className="md:w-1/2 flex justify-center md:block" >
            <img alt="Contact us" className="w-1/2  md:w-3/4" src={img} />
        </div>
        <div className="py-5 lg:py-20 md:w-1/2">
            <h2 className="md:py-5 flex justify-center md:block">Contact Us</h2>
            <div className="flex justify-center md:block">
                <form onSubmit={handleSubmit(onSubmit)}  className="w-3/4 ">
                    <div className="w-full">
                        <Input register={register("fullName")}  icon={<IoMdPerson/>} isDark={false} placeholder={"Full Name"}/>
                        {errors.fullName && <p className="p-0.5 text-error">{errors.fullName.message}</p>}
                    </div>
                    <div className="py-2 md:py-5  lg:py-10">
                        <Input register={register("email")} icon={<MdOutlineMail/>} isDark={false} placeholder={"Email"}/>
                        {errors.email && <p className="p-0.5 text-error">{errors.email.message}</p>}
                    </div>
                    <div className="w-full">
                        <textarea {...register("message")} style={{color:"black"}} className="w-full " placeholder="Message" />
                        {errors.message && <p className="p-0.5 text-error">{errors.message.message}</p>}
                    </div>
                    {appendReptcha && <div className="pt-5">
                    <CustomCaptcha isButtonClick={isButtonClick} onVerify={(res)=>{
                        setIsRecaptcha(res)
                    }}/>
                    </div> }
                    <div className="mt-10 flex justify-center md:block">
                        <button className="flex justify-center button-outline items-center" disabled={loading}>
                            {loading ?<div className="loader"></div>:"Send"}
                            </button>
                    </div>
                    
                    
                </form>
            </div>
        </div>

    </div> );
}
export default ContactUs;