import { useState ,useRef, useEffect} from "react";
import { useDispatch } from "react-redux";
import { notification } from "../../redux/actions/notification";
import ItemForm from "./itemForm";
import { alert } from "../../redux/actions/alert";
import { Helper } from "../../functionality/helper";
import { apiRoutes } from "../../functionality/apiRoutes";

// icons 
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleMinus } from "react-icons/ci";
import { BiCollapseAlt } from "react-icons/bi";

// for validation
import * as Yup from 'yup';
import GroupForm from "./groupForm";
import { getBrands, getCategory, getCoins } from "../../functionality/functions";
import ConfirmDeleted from "../../components/layouts/confirm";
import { useNavigate } from "react-router-dom";


const validationSchema = Yup.object({
    // email: Yup.string().email('Invalid email').required('Email is required'),
    name: Yup.string().min(4, 'Name must be at least 4 characters').required('Name is required'),
    address: Yup.string().min(3, 'Address must be at least 3 characters').required('Address is required'),
    // mobile: Yup.string().length(10, 'Mobile must be at 10 characters').required('Mobile is required'),
    // whatsapp: Yup.string().length(10, 'Whatsapp must be at 10 characters'),
    description :  Yup.string()

  });
function RequestsAds() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ isCollapse, setIsCollapse] = useState({
        1 : false
    })
    const [ counter, setCounter] = useState(0)
    const deletedLastGroupForm = ()=>{
        if(counter >1 ){
            const groupsAfterMinus = groups.filter(e=> e.count != counter)
            setGroups(groupsAfterMinus)
            setCounter(counter-1)
        }
    }
    const [ brands, setBrands] = useState([])
    const [ categories, setCategories] = useState([])
    const [ coins, setCoins] = useState([])
    const [ loading, setLoading] = useState(false)
    const [ items, setItems ] = useState([])
    const [ groups, setGroups ] = useState([
        {
            name : "",
            description : "",
            address : "",
            mobile : "",
            email : "",
            whatsapp : "",
            itemsView : false,
            id : "",
            loading : false
        }
    ])
    useEffect(()=>{
        const controller = new AbortController()
        const signal  = controller.signal
        if(!localStorage.getItem("user"))
            navigate("/")
        getAllCategories(signal)
        getAllBrands(signal)
        getAllCoins(signal)
        return()=> controller.abort()
    },[])
    const getAllCategories = async (signal)=>{
        const {res,msg} = await getCategory(signal)
        if(res){
            res.data.forEach(element => {
                setCategories(prev=>[...prev,{
                    value : element.id,
                    label : element.name
                }])
            });
           
        }

    }
    const getAllBrands = async (signal)=>{
        const {res,msg} = await getBrands(signal)
        if(res){
            res.data.forEach(element => {
                setBrands(prev=>[...prev,{
                    value : element.id,
                    label : element.name
                }])
            });
        }
    }
    const getAllCoins = async (signal)=>{
        const {res,msg} = await getCoins(signal)
        if(res){
            res.data.forEach(element => {
                setCoins(prev=>[...prev,{
                    value : element.id,
                    label : element.symbol
                }])
            });
        }
    }
    // handle Remove
    const removeForm = (index,id) =>{
        dispatch(alert({
            isOpen : true,
            component : <ConfirmDeleted loading={loading} onClick={()=>{removeOnClick(index,id)}} 
                        label={"Confirm"} message={"Are you sure Delete this Group"} />
        }))
    }
    const removeOnClick = async (index,id)=>{
        dispatch(alert({
            isOpen : true,
            component : <ConfirmDeleted loading={true} onClick={()=>{removeOnClick(index,id)}} 
                        label={"Confirm"} message={"Are you sure Delete this Group"} />
        }))
        const { response, message } = await Helper({
            url :  apiRoutes.group.delete(id),
            method : "DELETE",
            hasToken : true
        })
        if(response){
            setGroups(groups.filter((_, i) => i !== index));
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
            dispatch(alert({
                isOpen : false,
                component :<></>
            }))

        }else{
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
            dispatch(alert({
                isOpen : false,
                component :<></>
            }))

        }
        
    }

    const removeFormItem = (index,id) =>{
        
        dispatch(alert({
            isOpen : true,
            component : <ConfirmDeleted loading={loading} onClick={()=>{removeOnClickItem(index,id)}} 
                        label={"Confirm"} message={"Are you sure Delete this Item"} />
        }))
    }
    const removeOnClickItem = async (index,id)=>{
        dispatch(alert({
            isOpen : true,
            component : <ConfirmDeleted loading={true} onClick={()=>{removeOnClick(index,id)}} 
                        label={"Confirm"} message={"Are you sure Delete this Group"} />
        }))
        const { response, message } = await Helper({
            url :  apiRoutes.item.delete(id),
            method : "DELETE",
            hasToken : true
        })
        if(response){
            setItems(items.filter((e, i) => e.id !== id));
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
            dispatch(alert({
                isOpen : false,
                component :<></>
            }))
        }else{
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
            dispatch(alert({
                isOpen : false,
                component :<></>
            }))
        }
        
    }
    // handle add Group and Items
    const addGroup = () => {
        setGroups(prev=>[...prev,{
            name : "",
            description : "",
            address : "",
            mobile : "",
            email : "",
            whatsapp : "",
            itemsView : false,
            id : "",
            loading : false
        }])
    }
    const addItem = (group_id) => {
        setItems(prev=>[...prev,{
            name : "",
            brand_id : "",
            category_id : "",
            description : "",
            price : "",
            amount : "",
            total : "",
            status : "",
            img : "",
            id : "",
            group_id: group_id,
            isSaved : false,
            loading : false
        }])
    }

    // handle Submitting 
    const handleSubmitGroup = async (updatedData,i)=>{
        const updatedForms = [...groups];
        updatedData["loading"] = true
        updatedForms[i] = updatedData;
        
        setGroups(updatedForms)
        const { response , message } = await Helper({
            url : apiRoutes.group.add,
            method : "POST",
            hasToken : true,
            body : {
                name : updatedData.name,
                description : updatedData.description,
                address : updatedData.address
            }
        })
        if(response){
            updatedData["loading"] = false
            updatedData["itemsView"] = true
            updatedData["id"] = response.data.id
            updatedForms[i] = updatedData;
            setGroups(updatedForms)
            setItems(prev=>[...prev,{
                name : "",
                brand_id : "",
                category_id : "",
                description : "",
                price : "",
                amount : "",
                total : "",
                status : "",
                img : "",
                id : "",
                group_id : response.data.id,
                loading : false
            }])
            dispatch(notification({
                message : message,
                isOpen : true,
                bg_color : "bg-success",
            }))
        }else{

            const updatedFormsResponseError = [...groups];
            updatedData["loading"] = false
            updatedFormsResponseError[i] = updatedData;
            setItems(updatedFormsResponseError)
            dispatch(notification({
                message : message,
                isOpen : true,
                bg_color : "bg-error",
            }))
        }
        
    }
    const handleSubmitItem = async (updatedData,index)=>{
        const updatedForms = [...items];
        updatedData["loading"] = true
        updatedForms[index] = updatedData;
        setItems(updatedForms)
        const formData = new FormData()
        
        formData.append("group_id",updatedData.group_id)
        formData.append("category_id",updatedData.category_id)
        formData.append("brand_id",updatedData.brand_id)
        formData.append("name",updatedData.name)
        formData.append("is_new",updatedData.status)
        formData.append("price",parseFloat(updatedData.price))
        formData.append("amount",parseInt(updatedData.amount))
        formData.append("total",parseFloat(updatedData.total) )
        formData.append("image",updatedData.img)
        formData.append("coin_id",updatedData.coin_id)
        formData.append("minimum_sale",updatedData.minimum_sale)
        const body = {
            group_id : updatedData.group_id,
            category_id  : updatedData.category_id,
            brand_id : updatedData.brand_id,
            name : updatedData.name,
            is_new : updatedData.status,
            price : updatedData.price,
            amount : updatedData.amount,
            total : parseFloat(updatedData.total),
        }
        if(updatedData.description.length>0)
            formData.append("description",updatedData.description)
        if(updatedData.isSaved)
            uploadFile(updatedData.id , updatedData.img)
        const { response , message } = await Helper({
            url : updatedData.isSaved ? apiRoutes.item.update(updatedData.id) : apiRoutes.item.add,
            method : updatedData.isSaved ? "PUT" : "POST",
            hasToken : true,
            body : updatedData.isSaved? body : formData
        })
        if(response){
            const updatedFormsResponse = [...items];
            if(!updatedData.isSaved)
                updatedData["id"] = response.data.id
            updatedData["isSaved"] = true
            updatedFormsResponse[index] = updatedData;
            setItems(updatedFormsResponse)
            dispatch(notification({
                message : message,
                isOpen : true,
                bg_color : "bg-success",
            }))
        }else{
            const updatedFormsResponseError = [...items];
            updatedData["loading"] = false
            updatedFormsResponseError[index] = updatedData;
            setItems(updatedFormsResponseError)
            dispatch(notification({
                message : message,
                isOpen : true,
                bg_color : "bg-error",
            }))
        }
        
    }

    const uploadFile = async (id,img)=>{
        const formData = new FormData()
        console.log(img);
        
        formData.append("image",img)
        const {response , message, statusCode} = await Helper({
            url: apiRoutes.item.updateImage(id),
            method : "POST",
            hasToken : true,
            body : formData
        })
        if(response){
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-success",
                message : message
            }))
        }else{
            dispatch(notification({
                isOpen : true,
                bg_color : "bg-error",
                message : message
            }))
        }

    }
    return ( <div className="flex justify-center">
        <div className="card-group w-full lg:w-3/4">
            <div className="tools">
                <div className="cursor-pointer flex gap-1" onClick={addGroup}><IoIosAddCircleOutline  style={{fontSize:"20px"}}/>
                    <div className="flex items-center">Group</div>
                </div>
                {/* <div className="cursor-pointer"><CiCircleMinus onClick={deletedLastGroupForm} style={{fontSize:"25px"}}/> </div> */}
            </div>
            <div className="content flex flex-col gap-3">
               {groups.length>0?groups.map((ele,i)=>( <div key={`Group_${i}`}
                  className={`${isCollapse[ele.count]?"collapse_item":"collapse_item_open"} relative group-item`}>
                    <div className="flex justify-between">
                        <h3 >Group {i+1}</h3>
                    </div>
                    <div className="flex tools-card gap-3 flex justify-center">
                            {ele.itemsView && <div onClick={()=>addItem(ele.id)} style={{background:"#28377a"}} className="icon-svg w-3/4 cursor-pointer flex gap-1">
                                <IoIosAddCircleOutline  style={{fontSize:"20px"}}/>
                                    <div className="flex item-center"> Add Item</div>
                            </div>}
                                {/* <div className="cursor-pointer icon-svg"><BiCollapseAlt onClick={()=>setIsCollapse(prev=>({...prev,[ele.count]:!prev[ele.count]}))
                                } style={{fontSize:"22px"}} /></div> */}
                        </div>
                    <p className="py-1">Add groups with its items to send this request to Admin </p>
                    <GroupForm  key={`Form_${i}`}
                        index={i}
                        loading ={ele.loading}
                        formData = {groups}
                        appendButtonSave = {ele.itemsView}
                        validationSchema={validationSchema}
                        onRemove={() => removeForm(i,ele.id)}
                        onSave={(updatedData) => handleSubmitGroup(updatedData,i) }/>
                        {ele.itemsView && <div className="mt-3">
                        <h3 className="px-4" >Items</h3>
                        <div className="flex flex-col gap-4">
                        { items.map((item,index)=>(item.group_id == ele.id ?<ItemForm key={`Items_${index}`}
                            index = {i}
                            coins={coins}
                            categories = {categories}
                            brands = {brands}
                            loading ={item.loading}
                            isSaved = {item.isSaved}
                            group_id = {ele.id}
                            onRemove={() => removeFormItem(index,item.id)}
                            onSave={(updatedData) => handleSubmitItem(updatedData,index)}
                            />:"")
                            )}
                        </div>
                        
                    </div>}
                   
                    
                </div>)):<div className="flex min-h-50 lg:min-h-80 rounded empty-div flex justify-center items-center">
                    <h2>No Groups Added</h2>
                </div>}
            </div>
            
        </div>
    </div>);
}

export default RequestsAds;