export const userInfo = ({email,username,photo,password,mobile,fullName,id}) => {
    return{
        type: "USER-SUBSCRIBE-INFORMATION",
        email: email,
        username: username,
        photo: photo,
        password : password,
        mobile : mobile,
        fullName : fullName,
        id: id
    }
}