import { useEffect, useState , useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

function Laoding() {
    const loading = useSelector(state=>state.loading)
    const [ isOpen , setIsOpen ] = useState(false)
    useEffect(()=>{
        
        setIsOpen(loading.isOpen)
    },[loading])
    return ( isOpen && <div className="loading">
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>
    </div>
     );
}

export default Laoding;