import LazyImage from "../layouts/lazyLoadImages"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helper } from "../../functionality/helper";
import { apiRoutes, imgSrcCat } from "../../functionality/apiRoutes";
import defaultCat from "../../images/defaultCategory.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Mousewheel, Keyboard, Navigation } from 'swiper/modules';

function Categories() {
    const [ categories ,setCategories ] = useState([])
    const [ swiperArray , setSwiperArray] = useState([])
    const navigate = useNavigate()
    const [ loading, setLoading] = useState(true)
    useEffect(()=>{
        setCategories([])
        const controller = new AbortController()
        const signal = controller.signal
        const getCategories = async (signal)=>{
            const { response, message } = await Helper({
                url : apiRoutes.home.allCategoryWithCount(20,1),
                method : "GET",
                hasToken : true,
                signal
            })
            if(response){
                let temp = []
                let swArray = []
                for(let i=0; i <= response.data.length; i++ ){
                   
                    temp.push(response.data[i])
                    if ((i + 1) % 4 === 0 || i === response.data.length - 1) {
                        swArray.push({
                            name: `Swiper_${Math.ceil((i + 1) / 4)}`,
                            items: [...temp],
                        });
                        temp = []; // Reset the temporary array for the next group
                    }
                }
                setSwiperArray([...swArray])
                setLoading(false)
                
            }
        }
        getCategories(signal)
        return () => controller.abort()
    },[])
    return ( <div className="categories  min-h-64">
        <div className="flex justify-center py-5">
            <h2  >Our Categories</h2>
        </div>
        {loading?<div className='min-h-96 dynamic-pages w-full flex justify-center items-center'>
 
        <div className="bounce"></div>
        <div className="bounce"></div>
        <div className="bounce"></div>

        </div>: <Swiper
                cssMode={true}
                keyboard={true}
                slidesPerView={1}
                navigation={true} 
                mousewheel = {true}
                modules={[ Mousewheel,Navigation,Keyboard]}
                className="mySwiper "

                >
                    {swiperArray.length>0 && swiperArray.map((e,index)=>{
                        return <SwiperSlide key={"Category_"+e.name+"_"+index } >
                            <div className=" px-5 md:px-10  lg:px-24 grid grid-cols-1 md:grid-cols-2 gap-5 ">
                                    {
                                        e.items.map((item,i)=>{
                                            if(item){
                                                let image = ""
                                                if(item.image){
                                                    let str = item.image ? item.image : ""
                                                    let temp = str.split("/")
                                                    image = `${imgSrcCat + temp[1]}/${temp[2]}`
                                                }else{
                                                    image = defaultCat
                                                }
                                                
                                                
                                                return<div onClick={()=>navigate(`/items?category_id=${item.id}`)} key={`Category_${item.name}`} className={`box ${i%2!=0 ?"md:mt-5":""}`}>
                                                <div className="box-sun">
                                                <div>
                                                    <h2 className="text-center font-bold">{item.name}</h2>
                                                    <p className="text-center">{item.items_count}&nbsp;items</p>
                                                </div>
                                                </div>
                                                <div>
                                                    <img loading="lazy" alt={item.name} src={image?image:defaultCat}/>
                                                </div>
                            
                                            </div>
                                            }
                                            
                                        })
                                    }


                                </div>
                                
                            </SwiperSlide>
                    })}
                
            </Swiper>}
       
       
            
    </div>  );
}

export default Categories;