const initialValues = {
        isOpen : false,
        component :<></>
}

export const reducerAlert = (state = initialValues , action)=>{
    switch(action.type){
        case "OPEN-OR-CLOSE-ALERT":
            return{
                ...state,
                isOpen : action.isOpen,
                component : action.component
            }

        break;
        default :
            return { ...state }
            break;
    }
}