import { useEffect } from "react";
import SliderTab from "../../components/layouts/slideTab";
import { Outlet, useNavigate } from "react-router-dom";


function LayoutRequests() {
    const navigate = useNavigate()
    const list = [{
        name : "Requests",
        id : 1,
        link : "/requests"
    },{
        name : "Add Requests",
        id : 2,
        link : "/requests/add"
    }] 
    useEffect(()=>{
        if(!localStorage.getItem("user"))
          navigate("/")
      },[])

    return ( <div>
        <div className="flex justify-center">
            <SliderTab list={list} type={"requests"} returnedElement={(e)=>{
                if(`/${e.link}` != window.location.pathname){
                    navigate(`${e.link}`)
                }
        }} />
        </div>
        <div>
            <Outlet/>
        </div>
       
    </div> );
}

export default LayoutRequests;