import InputSearch from "../../components/layouts/inputWithIcon";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { HiMiniDevicePhoneMobile } from "react-icons/hi2";

// for validation
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';




function GroupForm({index, formData, validationSchema,loading, onRemove, onSave,appendButtonSave}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: formData,
        resolver: yupResolver(validationSchema),
    });
    
    // Submit handler
    const onSubmit = (data) => {
        console.log("Form Submitted: ", data);
        onSave(data);
    };
  

    return ( <div>
        <form  onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
            <div>
                <InputSearch register={register("name")}   isDark={true} icon={<MdOutlineDriveFileRenameOutline/>} placeholder={"Name Group"} />
                {errors.name && <p className="p-0.5 text-error">{errors.name.message}</p>}
            </div>
            <div className={` flex flex-col gap-3`}>
                <div>
                    <InputSearch register={register("address")}   isDark={true}  icon={<IoLocationOutline/>} placeholder={"Address"} />
                    {errors.address && <p className="p-0.5 text-error">{errors.address.message}</p>}
                </div>
                {/* <div>
                    <InputSearch register={register("email")} isDark={true} icon={<MdOutlineMail/>} placeholder={"Email"} />
                    {errors.email && <p className="p-0.5 text-error">{errors.email.message}</p>}
                </div> */}
                {/* <div>
                    <InputSearch register={register("mobile")} type={"number"}  isDark={true} icon={<HiMiniDevicePhoneMobile/>} placeholder={"Mobile"} />
                    {errors.mobile && <p className="p-0.5 text-error">{errors.mobile.message}</p>}
                </div> */}
                {/* <div>
                    <InputSearch register={register("whatsapp")} type={"number"}   isDark={true} icon={<FaWhatsapp/>} placeholder={"Whatsapp"} />
                    {errors.whatsapp && <p className="p-0.5 text-error">{errors.whatsapp.message}</p>}
                </div> */}
                <div >
                    <textarea {...register("description")} className="bg-inherit w-full"  isDark={true}  placeholder={"Description"} />
                    {errors.description && <p className="p-0.5 text-error">{errors.description.message}</p>}
                </div>
                <div style={{ marginTop: "10px" }}>
                    { !appendButtonSave && <button type="submit" className="flex justify-center items-center"  disabled={loading} >
                            {loading ? <div className="loader"></div>:"Save"}
                        </button>
                    }
                    
                    { appendButtonSave && <button type="button" className="mx-2 button-error" onClick={onRemove}>
                        Remove
                    </button>}
                </div>
                
            </div>
            
        </form> 
        
    </div> );
}

export default GroupForm;