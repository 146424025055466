import { useEffect, useState } from "react";

function SliderTab({list,returnedElement,type}) {

    
    const [item, setItem] =  useState(list[0])
    useEffect(()=>{
        if(type == "requests"){    
            let activeItem = {}
            if( window.location.pathname == "/requests/add"){
                activeItem =  list[1]
            }else if( window.location.pathname == "/requests"){
                activeItem = list[0]
            }
            setItem(activeItem)
        }else if (type == "products"){
            setItem(list[0])
        }
    },[type,window.location.pathname])
    return ( <div className="Slide-Tab flex">
        <ul className="flex gap-2 md:gap-6 ">
        {list.map((e,i)=>(<li  onClick={()=>{
            setItem(e)
            returnedElement(e)
        }} className={`${item.id == e.id?"active":""}`} key={`List_Tap_Slide_${e.name}_${e.index}`}>{e.name}</li>))}

    </ul>
    </div>  );
}

export default SliderTab;